import React__default, { Component } from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import '../typography.js';
import { ButtonPrimaryLink } from '../ButtonPrimary/ButtonPrimary.js';
import { MenuItem } from './MenuItem.js';
import { MenuButtons } from './MenuButtons.js';
import { SimpleMenuToggleButton } from './SimpleMenuToggleButton.js';

const elementDelay = 0.1;
class HeaderMobileSimple extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            menuOpen: this.props.menuOpen || false,
        };
        this.closeMenu = () => this.setState({ menuOpen: !this.state.menuOpen });
    }
    render() {
        const { items, logo, primaryButtonElement, primaryButtonText, primaryButtonUrl, } = this.props;
        const mobileItems = items.filter((item) => item.hideOnMobile !== true);
        return (React__default.createElement(Wrapper, null,
            React__default.createElement(MenuButtons, null,
                logo,
                React__default.createElement(SimpleMenuToggleButton, { open: this.state.menuOpen, onClick: this.closeMenu })),
            React__default.createElement(SimpleMenuItemsWrapper, { menuOpen: this.state.menuOpen },
                mobileItems.map((item, index) => (React__default.createElement(MenuItem, Object.assign({ simpleMenu: true, key: item.name, style: { animationDelay: `${index * elementDelay}s` }, closeMenu: this.closeMenu }, item)))),
                primaryButtonText && (React__default.createElement(ButtonPrimaryLink, { as: "a", href: primaryButtonUrl, style: {
                        marginTop: 5,
                        padding: "14px 25px",
                        color: "white",
                    } }, primaryButtonText)),
                primaryButtonElement && !primaryButtonText && primaryButtonElement)));
    }
}
const Wrapper = styled.div `
  width: 100vw;
  svg {
    height: 36px;
    width: auto;
    float: left;
  }

  @media ${device.desktop} {
    visibility: hidden;
    display: none;

    svg {
      height: 48px;
    }
  }
`;
const SimpleMenuItemsWrapper = styled.div `
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  overflow: hidden;
  opacity: ${(props) => (props.menuOpen ? "1" : "0")};
  height: ${(props) => (props.menuOpen ? "100vh" : "0vh")};

  * {
    font-family: HK Grotesk;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.13px;
    text-align: center;
    color: #3c3f3e;
  }
`;

export { HeaderMobileSimple };
