import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { Subheading, SectionHeader, TextBody } from '../typography.js';
import '../Emphasis/Emphasis.js';
import { EmphasizedText } from '../EmphasizedText/EmphasizedText.js';
import { CenteredContentWrapper } from '../layout/CenteredContentWrapper.js';
import { Confetti5Rows } from '../Icons/Confetti5Rows.js';

const ThreeUpCollage = (props) => (React__default.createElement(CenteredContentWrapper, { style: { textAlign: "center" }, id: props.id },
    React__default.createElement(Wrapper, { style: props.style },
        React__default.createElement(Collage, { imgLeft: props.imgLeft, imgMain: props.imgMain, imgBottom: props.imgBottom, imgLeftStyle: props.imgLeftStyle, imgMainStyle: props.imgMainStyle, imgBottomStyle: props.imgBottomStyle }),
        React__default.createElement(ContentWrapper, null,
            React__default.createElement(Subheading, null, props.subheading),
            React__default.createElement(SectionHeader, null,
                React__default.createElement(EmphasizedText, { text: props.title, highlight: props.highlightedTitleFragments })),
            React__default.createElement(TextBody, null, props.contentBlock)))));
ThreeUpCollage.defaultProps = {
    "data-testid": "three-up-collage",
};
const Collage = (props) => {
    const ImgLeft = React__default.cloneElement(props.imgLeft, {
        "data-testid": "collage-image",
        style: Object.assign(Object.assign(Object.assign({}, (props.imgLeft && props.imgLeft.props && props.imgLeft.props.style)), { gridColumn: "2 / 5", gridRow: "6 / 10" }), props.imgLeftStyle),
    });
    const ImgMain = React__default.cloneElement(props.imgMain, {
        "data-testid": "collage-image",
        style: Object.assign(Object.assign(Object.assign({}, (props.imgMain && props.imgMain.props && props.imgMain.props.style)), { gridColumn: "5 / 10", gridRow: "8 / 12" }), props.imgMainStyle),
    });
    const ImgBottom = React__default.cloneElement(props.imgBottom, {
        "data-testid": "collage-image",
        style: Object.assign(Object.assign(Object.assign({}, (props.imgBottom &&
            props.imgBottom.props &&
            props.imgBottom.props.style)), { gridColumn: "5 / 12", gridRow: "1 / 8" }), props.imgBottomStyle),
    });
    return (React__default.createElement(CollageWrapper, null,
        React__default.createElement(Confetti5Rows, { "data-testid": "confetti-left", style: {
                zIndex: -1,
                gridColumn: "2 / 5",
                gridRow: "6 / 10",
                position: "relative",
                top: -40,
                left: -80,
                transform: "scaleY(-1)",
            } }),
        ImgLeft,
        ImgMain,
        ImgBottom,
        React__default.createElement(Confetti5Rows, { "data-testid": "confetti-right", style: {
                zIndex: -1,
                gridColumn: "12",
                gridRow: "8",
                position: "relative",
                transform: "translate(calc(-100% + 11px), calc(-100% + 11px))",
            } })));
};
const Wrapper = styled.div `
  display: block;
  > div {
    max-width: 100%;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    > div {
      max-width: 50%;
    }
  }
`;
const CollageWrapper = styled.div `
  display: grid;
  max-height: 400px;
  grid-template-rows: repeat(11, minmax(11px, 8%));
  grid-template-columns: repeat(12, minmax(10px, 8%));
  grid-row-gap: 11px;
  grid-column-gap: 11px;

  @media ${device.tablet} {
    position: relative;
    max-height: 500px;
    right: 200px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    grid-template-rows: repeat(11, 20px);
    grid-template-columns: repeat(12, 20px);
  }

  @media ${device.desktop} {
    position: relative;
    max-height: 800px;
    right: 280px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
    grid-template-rows: repeat(11, 40px);
    grid-template-columns: repeat(12, 40px);
  }
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 31px ${spacing.mobile.padding.default}px 0;

  @media ${device.desktop} {
    padding: 0 ${spacing.mobile.padding.default}px;
  }

  @media ${device.mobile} {
    padding: 31px 0;
  }
  
  & > p {
    text-align: left;
  }
`;

export { ThreeUpCollage };
