import React__default from 'react';
import styled from 'styled-components';

const SchemaDesignSessionsIcon = (props) => (React__default.createElement(Icon, Object.assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100" }, props),
    React__default.createElement("circle", { className: "cls-1", cx: "79", cy: "83", r: "5" }),
    React__default.createElement("path", { className: "cls-2", d: "M80,52.97V13H33v2h45V52.97l-5,6.43v23.6c0,1.6,.62,3.11,1.76,4.24,1.13,1.13,2.64,1.76,4.24,1.76s3.11-.62,4.24-1.76c1.13-1.13,1.76-2.64,1.76-4.24v-23.6l-5-6.43Zm1.83,32.86c-1.51,1.51-4.14,1.51-5.66,0-.76-.76-1.17-1.76-1.17-2.83s.42-2.07,1.17-2.83,1.76-1.17,2.83-1.17,2.07,.42,2.83,1.17,1.17,1.76,1.17,2.83-.42,2.07-1.17,2.83Zm1.17-7.29c-1.1-.99-2.51-1.54-4-1.54s-2.9,.55-4,1.54v-18.45l4-5.14,4,5.14v18.45Z" }),
    React__default.createElement("g", null,
        React__default.createElement("circle", { className: "cls-2", cx: "59.5", cy: "74", r: "1.5" }),
        React__default.createElement("circle", { className: "cls-2", cx: "50.5", cy: "74", r: "1.5" }),
        React__default.createElement("circle", { className: "cls-2", cx: "41.5", cy: "74", r: "1.5" })),
    React__default.createElement("g", null,
        React__default.createElement("path", { className: "cls-2", d: "M19,37c0,3.31,2.69,6,6,6v-2c-2.21,0-4-1.79-4-4v-4c0-2.09-1.07-3.92-2.69-5,1.62-1.08,2.69-2.91,2.69-5v-4c0-2.21,1.79-4,4-4v-2c-3.31,0-6,2.69-6,6v4c0,2.21-1.79,4-4,4v2c2.21,0,4,1.79,4,4v4Z" }),
        React__default.createElement("path", { className: "cls-2", d: "M25,69v-4c0-3.31-2.69-6-6-6v2c2.21,0,4,1.79,4,4v4c0,2.09,1.07,3.92,2.69,5-1.62,1.08-2.69,2.91-2.69,5v4c0,2.21-1.79,4-4,4v2c3.31,0,6-2.69,6-6v-4c0-2.21,1.79-4,4-4v-2c-2.21,0-4-1.79-4-4Z" })),
    React__default.createElement("g", null,
        React__default.createElement("rect", { className: "cls-1", x: "33", y: "26", width: "34", height: "8" }),
        React__default.createElement("rect", { className: "cls-2", x: "33", y: "25", width: "34", height: "2" })),
    React__default.createElement("g", null,
        React__default.createElement("rect", { className: "cls-1", x: "33", y: "42", width: "20", height: "8" }),
        React__default.createElement("rect", { className: "cls-2", x: "33", y: "41", width: "20", height: "2" })),
    React__default.createElement("g", null,
        React__default.createElement("rect", { className: "cls-1", x: "33", y: "58", width: "28", height: "8" }),
        React__default.createElement("rect", { className: "cls-2", x: "33", y: "57", width: "28", height: "2" }))));
const Icon = styled.svg `
  .cls-1{fill:#bae1d3;}.cls-2{fill:#323433;}
`;
SchemaDesignSessionsIcon.defaultProps = {
    "data-testid": "icon-schema-design-sessions",
};

export { SchemaDesignSessionsIcon };
