import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { SectionHeaderSmall, TextBody } from '../typography.js';
import { ButtonPrimaryLink } from '../ButtonPrimary/ButtonPrimary.js';
import '../Icons/Confetti9Rows.js';
import { ToutImageConfettiWrapper } from './ToutImageConfettiWrapper.js';

const ImageTout = ({ title, content, image, url, 
// subheading,
buttonText, }) => {
    const imageWithProps = React__default.cloneElement(image, {
        "data-testid": "tout-image",
    });
    return (React__default.createElement(Wrapper, null,
        React__default.createElement(ToutImageConfettiWrapper, { image: imageWithProps }),
        React__default.createElement(ContentWrapper, null,
            React__default.createElement(SectionHeaderSmall, { style: { fontSize: "36px !important" } }, title),
            React__default.createElement(TextBody, null, content),
            React__default.createElement(ButtonPrimaryLink, { as: "a", href: url, style: {
                    marginTop: 20,
                    display: "inline-block",
                    width: "fit-content",
                }, target: "_blank" }, buttonText))));
};
ImageTout.defaultProps = {
    buttonText: "Learn more",
};
const ContentWrapper = styled.div `
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @media ${device.mobile} {
    h1 {
      font-size: 36px;
    }
  }

  @media ${device.tablet} {
    margin-top: -24px;
    margin-left: 97px;
    display: inline-flex;
    flex-direction: column;

    h1 {
      margin: 0;
    }
  }
`;
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
    > * {
      max-width: 50%;
    }
  }

  @media ${device.desktop} {
    padding: 0;
  }
`;

export { ImageTout };
