import React__default from 'react';
import styled from 'styled-components';

const SupergraphProjectRoadmapIcon = (props) => (React__default.createElement(Icon, Object.assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100" }, props),
    React__default.createElement("circle", { className: "cls-2", cx: "78", cy: "22", r: "1" }),
    React__default.createElement("circle", { className: "cls-2", cx: "78", cy: "28", r: "1" }),
    React__default.createElement("circle", { className: "cls-2", cx: "78", cy: "34", r: "1" }),
    React__default.createElement("g", null,
        React__default.createElement("rect", { className: "cls-1", x: "21", y: "43", width: "29", height: "8" }),
        React__default.createElement("rect", { className: "cls-2", x: "21", y: "42", width: "29", height: "2" })),
    React__default.createElement("g", null,
        React__default.createElement("rect", { className: "cls-1", x: "38", y: "52", width: "23", height: "8" }),
        React__default.createElement("rect", { className: "cls-2", x: "38", y: "51", width: "23", height: "2" })),
    React__default.createElement("g", null,
        React__default.createElement("rect", { className: "cls-1", x: "61", y: "61", width: "9", height: "8" }),
        React__default.createElement("rect", { className: "cls-2", x: "61", y: "60", width: "9", height: "2" })),
    React__default.createElement("g", null,
        React__default.createElement("rect", { className: "cls-1", x: "70", y: "70", width: "9", height: "8" }),
        React__default.createElement("rect", { className: "cls-2", x: "70", y: "69", width: "9", height: "2" })),
    React__default.createElement("rect", { className: "cls-2", x: "56", y: "21", width: "14", height: "2" }),
    React__default.createElement("rect", { className: "cls-2", x: "56", y: "27", width: "10", height: "2" }),
    React__default.createElement("rect", { className: "cls-2", x: "56", y: "33", width: "14", height: "2" }),
    React__default.createElement("polygon", { className: "cls-2", points: "79 78 22 78 22 21 20 21 20 27 16 27 16 29 20 29 20 42 16 42 16 44 20 44 20 57 16 57 16 59 20 59 20 72 16 72 16 74 20 74 20 80 26 80 26 84 28 84 28 80 41 80 41 84 43 84 43 80 56 80 56 84 58 84 58 80 71 80 71 84 73 84 73 80 79 80 79 78" })));
const Icon = styled.svg `
  .cls-1{fill:#bae1d3;}.cls-2{fill:#323433;}
`;
SupergraphProjectRoadmapIcon.defaultProps = {
    "data-testid": "icon-supergraph-project-roadmap",
};

export { SupergraphProjectRoadmapIcon };
