import React__default from 'react';

const FlipchartSmallIcon = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "64px", height: "64px", viewBox: "0 0 64 64", version: "1.1" }, props),
    React__default.createElement("title", null, "Flipchart icon"),
    React__default.createElement("g", { id: "Symbols", stroke: "none", fill: "none", fillRule: "evenodd" },
        React__default.createElement("g", { id: "ic-/-64-/-training" },
            React__default.createElement("rect", { id: "Rectangle", x: "0", y: "0", width: "64", height: "64" }),
            React__default.createElement("g", { id: "training", transform: "translate(6.000000, 8.000000)" },
                React__default.createElement("polygon", { fill: "#BAE1D3", points: "39 42 53 42 53 1 39 1" }),
                React__default.createElement("path", { d: "M0,40 L50,40 L50,3 L0,3 L0,40 Z M2,38 L2,5 L48,5 L48,38 L2,38 Z", fill: "#3C3F3E", fillRule: "nonzero" }),
                React__default.createElement("polygon", { fill: "#323433", fillRule: "nonzero", points: "29 18.5 37 18.5 37 16.5 29 16.5" }),
                React__default.createElement("polygon", { fill: "#323433", fillRule: "nonzero", points: "29 27.5 39 27.5 39 25.5 29 25.5" }),
                React__default.createElement("polygon", { fill: "#323433", fillRule: "nonzero", points: "29 22.5 42 22.5 42 20.5 29 20.5" }),
                React__default.createElement("polygon", { fill: "#3C3F3E", fillRule: "nonzero", points: "24.5 0 24.5 4 26.5 4 26.5 0" }),
                React__default.createElement("polygon", { fill: "#3C3F3E", fillRule: "nonzero", points: "20.080855 38.6060807 17.080855 45.6060807 18.919145 46.3939193 21.919145 39.3939193" }),
                React__default.createElement("polygon", { fill: "#3C3F3E", fillRule: "nonzero", points: "29.080855 39.3939193 32.080855 46.3939193 33.919145 45.6060807 30.919145 38.6060807" }),
                React__default.createElement("path", { d: "M7,21 C7,16.0292227 11.0295132,12 16,12 L16,21 L7,21 Z", fill: "#BAE1D3" }),
                React__default.createElement("path", { d: "M18.5449178,14.9413915 C21.195212,15.8910355 23,18.4114295 23,21.2740354 C23,24.9889166 19.9893809,28 16.2753917,28 C13.4115484,28 10.8903043,26.1929313 9.94159731,23.5401509 L8.05840269,24.2136329 C9.2894429,27.6558752 12.5599942,30 16.2753917,30 C21.0940195,30 25,26.093417 25,21.2740354 C25,17.5604332 22.6588104,14.2909522 19.2195499,13.0586085 L18.5449178,14.9413915 Z", fill: "#323433", fillRule: "nonzero" }),
                React__default.createElement("path", { d: "M6,22 L6,21 C6,15.4770333 10.4771331,11 16,11 L17,11 L17,22 L6,22 Z M15,20 L15,13.0618913 C11.3812551,13.5131249 8.51312649,16.3812049 8.06189061,20 L15,20 Z", fill: "#3C3F3E", fillRule: "nonzero" }))))));
FlipchartSmallIcon.defaultProps = {
    "data-testid": "icon-flipchart-small",
};

export { FlipchartSmallIcon };
