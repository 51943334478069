import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { e as AcceptanceGreenUltimatelyLight } from '../../../colors-c52fac99.js';

const SplitBackground = styled.div `
  position: relative;
  height: 100%;
  min-height: calc(666px - 80px - 40px);
  padding: 80px ${spacing.mobile.padding.default}px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    ${AcceptanceGreenUltimatelyLight} 50%,
    ${AcceptanceGreenUltimatelyLight} 100%
  );

  @media ${device.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export { SplitBackground };
