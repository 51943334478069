import React__default from 'react';
import styled from 'styled-components';

const SquareIconWithNumber = ({ numberToShow }) => {
    return (React__default.createElement(Square, null, numberToShow));
};
const Square = styled.div `
  font-weight: bold;
  margin-top: 20px;
  width: 50px;
  height: 50px;
  background-color: #6C7578;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
`;

export { SquareIconWithNumber };
