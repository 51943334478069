import React__default from 'react';
import styled from 'styled-components';
import { Confetti9Rows } from '../Icons/Confetti9Rows.js';

const ToutImageConfettiWrapper = ({ image }) => (React__default.createElement(Wrapper, null,
    React__default.createElement(Confetti9Rows, { style: {
            transform: "scaleX(-1)",
            position: "absolute",
            left: 10,
            bottom: "25%",
            zIndex: -1,
        } }),
    image,
    React__default.createElement(Confetti9Rows, { style: {
            position: "absolute",
            right: 10,
            top: "25%",
            zIndex: -1,
        } })));
const Wrapper = styled.div `
  z-index: 1;
  position: relative;
  display: block;
  width: 100%;
  > img {
    max-width: 100%;
  }
`;

export { ToutImageConfettiWrapper };
