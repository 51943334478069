import React__default from 'react';

const ScreenSmallIcon = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "64", height: "64", viewBox: "0 0 64 64" }, props),
    React__default.createElement("title", null, "Screen icon"),
    React__default.createElement("g", { fill: "none", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 0h64v64H0z" }),
        React__default.createElement("path", { fill: "#BAE1D3", d: "M28 7H2v46h26z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M4 50h57V9H4v41zm2-2V11h53v37H6z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M14 26.5h32v-2H14zM14 36.5h19v-2H14zM14 31.25h13v-2H14zM31.5 49v7h2v-7z" }),
        React__default.createElement("path", { fill: "#323433", d: "M14 18a1 1 0 1 0 2 0 1 1 0 0 0-2 0M17 18a1 1 0 1 0 2 0 1 1 0 0 0-2 0M20 18a1 1 0 1 0 2 0 1 1 0 0 0-2 0" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M15 56.5h35v-2H15z" }))));
ScreenSmallIcon.defaultProps = {
    "data-testid": "icon-screen-small",
};

export { ScreenSmallIcon };
