import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { PageHeader, TextBody, Subheading, SectionHeader } from '../typography.js';
import { HeroBackgroundWrapper } from './HeroBackground.js';
import '../Emphasis/Emphasis.js';
import { EmphasizedText } from '../EmphasizedText/EmphasizedText.js';
import { CenteredContentWrapper } from '../layout/CenteredContentWrapper.js';

const Hero = (props) => {
    return (React__default.createElement(Wrapper, { style: props.style, id: props.id },
        React__default.createElement(HeroBackgroundWrapper, { height: props.height, "data-testid": props["data-testid"], inline: props.inline }, props.elementWithBackgroundImage),
        !props.inline && (React__default.createElement(ImageOverlay, null,
            React__default.createElement(PageHeader, null,
                React__default.createElement(EmphasizedText, { text: props.title, highlight: props.highlightedTitleFragments })),
            React__default.createElement(TextBody, { fontSize: "20px" }, props.contentBlock),
            React__default.createElement(ActionButtons, null, props.actionButtons))),
        React__default.createElement(SectionContent, { inline: props.inline },
            props.inline && (React__default.createElement(React__default.Fragment, null,
                React__default.createElement(Subheading, null, props.subheading),
                React__default.createElement(SectionHeader, null, props.heading))),
            React__default.createElement(ChildrenWrapper, null, props.children))));
};
Hero.defaultProps = {
    contentBlock: () => null,
    actionButtons: () => null,
    "data-testid": "hero",
};
const Wrapper = styled.div `
  position: relative;
`;
const ActionButtons = styled.div `
  margin-top: 25px;
`;
const ImageOverlay = styled(CenteredContentWrapper) `
  position: absolute;
  top: 141px;

  @media ${device.wide} {
    left: calc((100% - ${spacing.desktop.maxContentWidth}px) / 2);
  }

  padding-top: 10px;
  max-width: 500px;

  @media ${device.tabletOnly} {
    > * {
      max-width: 100%;
    }
  }

  @media ${device.mobile} {
    position: relative;
    top: -75px;

    background: #ffffff;
    left: 0;
    margin: 0 ${spacing.mobile.padding.default}px;
    max-width: 100%;

    h1 {
      padding-right: 24px;
    }

    > * {
      text-align: center;
    }
  }
`;
const SectionContent = styled.div `
  position: relative;
  top: -75px;
  padding-top: 100px;
  background: #ffffff;
  left: 0;
  margin: 0 ${spacing.mobile.padding.default}px -75px;
  max-width: 100%;
  min-height: 50px;

  > * {
    text-align: left;
  }

  h1 {
    padding-right: 24px;
  }

  > * {
    text-align: left;
  }

  @media ${device.desktop} {
    top: -146px;
    margin-right: auto;
    margin-left: 0;

    max-width: ${spacing.desktop.maxContentWidth}px;
    padding-left: calc((100% - ${spacing.desktop.maxContentWidth}px) / 2);
  }

  ${(props) => props.inline
    ? `
    @media ${device.desktop} {
        padding-left: 24px;
    }
    
    @media ${device.wide} {
        padding-top: 100px;
        padding-left: calc((100% - ${spacing.desktop.maxContentWidth}px) / 2);
    }
    `
    : ""}
`;
const ChildrenWrapper = styled.div `
  position: relative;
  @media ${device.mobile} {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  @media ${device.tablet} {
    margin-bottom: 90px;
  }

  @media ${device.desktop} {
    margin-top: initial;
    margin-bottom: initial;
  }
`;

export { Hero };
