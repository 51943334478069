import React__default from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg `
  .cls-1 {
    fill:#fff;
  }
  .cls-2 {
    fill:#231f20;
  }
  .cls-3 {
     fill:#59c098;
  }
`;
const XolvioLogoVertical = (props) => (React__default.createElement(StyledIcon, Object.assign({ width: "89px", height: "124px", viewBox: "0 0 76 107", version: "1.1", xmlns: "http://www.w3.org/2000/svg" }, props),
    React__default.createElement("title", null, "Xolvio logo with name"),
    React__default.createElement("g", null,
        React__default.createElement("rect", { className: "cls-3", x: "0", width: "76", height: "76" }),
        React__default.createElement("g", null,
            React__default.createElement("rect", { className: "cls-1", x: "21.87", y: "22.91", width: "32.27", height: "7.11" }),
            React__default.createElement("polygon", { className: "cls-1", points: "54.13 55.16 54.13 63.38 21.87 41.41 21.87 33.2 54.13 55.16" }),
            React__default.createElement("rect", { className: "cls-1", x: "47.02", y: "12.62", width: "7.11", height: "7.11" }))),
    React__default.createElement("g", null,
        React__default.createElement("path", { className: "cls-2", d: "M54.11,85.47h3.61v3.52h-3.61v-3.52Zm0,5.14h3.61v15.87h-3.61v-15.87Z" }),
        React__default.createElement("path", { className: "cls-2", d: "M61.75,92.62c1.6-1.6,3.66-2.48,5.82-2.48,2.31,0,4.23,.77,5.82,2.28,1.66,1.6,2.6,3.78,2.6,5.97,0,2.39-.77,4.4-2.36,6.03-1.6,1.66-3.64,2.48-6,2.48s-4.32-.83-5.94-2.54c-1.51-1.63-2.34-3.64-2.34-5.88s.83-4.29,2.4-5.85m5.91,10.61c2.69,0,4.49-1.83,4.49-4.88,0-2.72-1.95-4.58-4.49-4.58s-4.49,1.92-4.49,4.67c0,2.99,1.77,4.79,4.49,4.79" }),
        React__default.createElement("polygon", { className: "cls-2", points: "4.05 106.48 0 106.48 5.38 98.24 .62 90.61 4.76 90.61 7.51 95.13 10.32 90.61 14.63 90.61 9.58 98.15 14.69 106.48 10.49 106.48 7.33 101.43 4.05 106.48" }),
        React__default.createElement("path", { className: "cls-2", d: "M16.16,92.62c1.6-1.6,3.67-2.48,5.82-2.48,2.31,0,4.23,.77,5.82,2.28,1.66,1.6,2.6,3.78,2.6,5.97,0,2.39-.77,4.4-2.36,6.03-1.6,1.66-3.64,2.48-6,2.48s-4.32-.83-5.94-2.54c-1.51-1.63-2.34-3.64-2.34-5.88s.83-4.29,2.39-5.85m5.91,10.61c2.69,0,4.49-1.83,4.49-4.88,0-2.72-1.95-4.58-4.49-4.58s-4.49,1.92-4.49,4.67c0,2.99,1.77,4.79,4.49,4.79" }),
        React__default.createElement("rect", { className: "cls-2", x: "32.23", y: "85.47", width: "3.61", height: "21.02" }),
        React__default.createElement("polygon", { className: "cls-2", points: "43.22 106.48 37.19 90.61 41.12 90.61 44.81 101.16 48.63 90.61 52.47 90.61 46.11 106.48 43.22 106.48" }))));
XolvioLogoVertical.defaultProps = {
    "data-testid": "xolvio-logo-vertical",
};

export { XolvioLogoVertical };
