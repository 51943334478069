import React__default from 'react';
import styled from 'styled-components';

const SimpleMenuToggleButton = (props) => {
    const styles = {
        lineTop: {
            transform: props.open ? "translate(6px, 0px) rotate(45deg)" : "none",
            transformOrigin: "top left",
            marginBottom: "5px",
        },
        lineMiddle: {
            opacity: props.open ? 0 : 1,
            transform: props.open ? "translateX(-16px)" : "none",
        },
        lineBottom: {
            transform: props.open ? "translate(4px, 5px) rotate(-45deg)" : "none",
            transformOrigin: "top left",
            marginTop: "5px",
        },
    };
    return (React__default.createElement(SimpleMenuToggleButtonWrapper, { onClick: props.onClick, open: props.open },
        React__default.createElement(Line, { style: styles.lineTop, open: props.open }),
        React__default.createElement(Line, { style: styles.lineMiddle }),
        React__default.createElement(Line, { style: styles.lineBottom, open: props.open })));
};
const SimpleMenuToggleButtonWrapper = styled.div `
  height: 24px;
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  transform: translateX(5px);
`;
const Line = styled.div `
  height: 2px;
  width: ${props => props.open ? '26px' : '24px'};
  background: #56bf98;
  transition: all 0.2s ease;
`;

export { SimpleMenuToggleButton };
