import React__default from 'react';

const SolutionIdeationIcon = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "Solution Ideation & Validation icon"),
    React__default.createElement("defs", null,
        React__default.createElement("style", null, `
      .cls02-1 {
        fill: #bae1d3;
      }

      .cls02-2, .cls02-3 {
        fill: none;
        stroke: #323433;
        stroke-width: 2px;
      }

      .cls02-2 {
        stroke-linecap: square;
      }

      .cls02-4 {
        fill: #323433;
      }
          `)),
    React__default.createElement("g", { id: "Layer_1", "data-name": "Layer 1" },
        React__default.createElement("path", { className: "cls02-1", d: "M71.41,46.14h0L38.58,62.24A4.12,4.12,0,0,1,33,56.66L48.92,24.22h0A16.66,16.66,0,1,1,71.41,46.14Z" }),
        React__default.createElement("polyline", { className: "cls02-2", points: "32.53 66.28 14.54 66.28 14.54 35.58 45.23 35.58 45.23 66.28" }),
        React__default.createElement("polyline", { className: "cls02-3", points: "55.52 57.51 67.17 57.51 67.17 88.21 36.47 88.21 36.47 83.03" }),
        React__default.createElement("circle", { className: "cls02-3", cx: "67.3", cy: "27.96", r: "16.66" }),
        React__default.createElement("circle", { className: "cls02-3", cx: "36.64", cy: "58.6", r: "4.12" }),
        React__default.createElement("circle", { className: "cls02-4", cx: "67.3", cy: "27.96", r: "1.38" }),
        React__default.createElement("line", { className: "cls02-2", x1: "14.54", y1: "22.14", x2: "39.36", y2: "22.14" }),
        React__default.createElement("line", { className: "cls02-2", x1: "14.54", y1: "15.77", x2: "33.15", y2: "15.77" }),
        React__default.createElement("line", { className: "cls02-2", x1: "14.54", y1: "28.52", x2: "26.95", y2: "28.52" }),
        React__default.createElement("polyline", { className: "cls02-3", points: "40.76 58.6 40.76 77.74 36.64 83.03 32.53 77.74 32.53 58.6" }),
        React__default.createElement("circle", { className: "cls02-4", cx: "74.73", cy: "58.46", r: "1.38" }),
        React__default.createElement("circle", { className: "cls02-4", cx: "74.73", cy: "66.28", r: "1.38" }),
        React__default.createElement("circle", { className: "cls02-4", cx: "74.73", cy: "74.09", r: "1.38" }))));
SolutionIdeationIcon.defaultProps = {
    "data-testid": "solution-ideation-icon",
};

export { SolutionIdeationIcon };
