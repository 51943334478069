import React__default from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg `
  .cls-1 {
    fill:#fff;
  }
  .cls-2 {
    fill:#231f20;
  }
  .cls-3 {
    fill:#59c098;
  }
`;
const XolvioLogo = (props) => (React__default.createElement(StyledIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "129", height: "40", viewBox: "0 0 129 40", onClick: () => (window.location.href = "/") }, props),
    React__default.createElement("title", null, "Xolvio logo"),
    React__default.createElement("rect", { className: "cls-3", width: "40", height: "40" }),
    React__default.createElement("g", null,
        React__default.createElement("rect", { className: "cls-1", x: "11.51", y: "12.06", width: "16.98", height: "3.74" }),
        React__default.createElement("polygon", { className: "cls-1", points: "28.49 29.03 28.49 33.36 11.51 21.8 11.51 17.47 28.49 29.03" }),
        React__default.createElement("rect", { className: "cls-1", x: "24.75", y: "6.64", width: "3.74", height: "3.74" })),
    React__default.createElement("path", { className: "cls-2", d: "M105.44,9.19h3.64v3.55h-3.64v-3.55Zm0,5.19h3.64V30.39h-3.64V14.38Z" }),
    React__default.createElement("path", { className: "cls-2", d: "M113.15,16.41c1.61-1.61,3.7-2.5,5.87-2.5,2.33,0,4.26,.78,5.87,2.3,1.67,1.61,2.62,3.82,2.62,6.02,0,2.42-.78,4.44-2.38,6.08-1.61,1.67-3.67,2.51-6.05,2.51s-4.35-.84-5.99-2.56c-1.52-1.64-2.36-3.67-2.36-5.93s.84-4.32,2.42-5.9m5.96,10.7c2.71,0,4.53-1.85,4.53-4.92,0-2.74-1.97-4.62-4.53-4.62s-4.53,1.94-4.53,4.71c0,3.01,1.79,4.83,4.53,4.83" }),
    React__default.createElement("polygon", { className: "cls-2", points: "54.95 30.39 50.86 30.39 56.29 22.07 51.49 14.38 55.66 14.38 58.44 18.94 61.27 14.38 65.62 14.38 60.53 21.98 65.68 30.39 61.45 30.39 58.26 25.29 54.95 30.39" }),
    React__default.createElement("path", { className: "cls-2", d: "M67.16,16.41c1.61-1.61,3.7-2.5,5.87-2.5,2.33,0,4.26,.78,5.87,2.3,1.67,1.61,2.62,3.82,2.62,6.02,0,2.42-.78,4.44-2.39,6.08-1.61,1.67-3.67,2.51-6.05,2.51s-4.35-.84-5.99-2.56c-1.52-1.64-2.36-3.67-2.36-5.93s.84-4.32,2.42-5.9m5.96,10.7c2.71,0,4.53-1.85,4.53-4.92,0-2.74-1.97-4.62-4.53-4.62s-4.53,1.94-4.53,4.71c0,3.01,1.79,4.83,4.53,4.83" }),
    React__default.createElement("rect", { className: "cls-2", x: "83.37", y: "9.19", width: "3.64", height: "21.2" }),
    React__default.createElement("polygon", { className: "cls-2", points: "94.46 30.39 88.37 14.38 92.34 14.38 96.07 25.02 99.91 14.38 103.79 14.38 97.38 30.39 94.46 30.39" })));
XolvioLogo.defaultProps = {
    "data-testid": "xolvio-logo",
};

export { XolvioLogo };
