import React__default from 'react';
import styled from 'styled-components';
import { a as TextMedium } from '../../../colors-c52fac99.js';
import { CheckIcon } from '../Icons/CheckIcon.js';
import { CheckIconThin } from '../Icons/CheckIconThin.js';

const ListChecked = (props) => {
    const { children, thinCheckIcon } = props;
    return (React__default.createElement(ListWrapper, null, children.map((c, index) => (React__default.createElement(Li, { key: index },
        c === "" ? (React__default.createElement("br", null)) : thinCheckIcon ? (React__default.createElement(CheckIconThin, { style: { transform: "translate(-20px, 5px)" } })) : (React__default.createElement(CheckIcon, { style: { transform: "translate(-20px, 5px)" } })),
        c)))));
};
const ListWrapper = styled.ul `
  margin: 0 5px;
  text-indent: -17px;
`;
const Li = styled.li `
  text-align: left;
  list-style: none;

  font-family: "HK Grotesk Light", "HK Grotesk";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.2px;
  color: ${TextMedium};

  :not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

export { ListChecked };
