import React__default, { Component } from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { Subheading, SectionHeader } from '../typography.js';
import '../Emphasis/Emphasis.js';
import { EmphasizedText } from '../EmphasizedText/EmphasizedText.js';
import { ArrowRightIcon } from '../Icons/ArrowRight.js';
import '../Icons/Confetti3x5.js';
import '../Icons/Confetti7Rows.js';
import { Testimonial } from './Testimonial.js';
import { ArrowLeftIcon } from '../Icons/ArrowLeft.js';

const defaultProps = {
    subheading: "TESTIMONIAL",
    heading: "We deliver",
    highlightedTitleFragments: "deliver",
};
class Testimonials extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            displayedIndex: 0,
        };
        this.showNext = () => {
            const { displayedIndex } = this.state;
            const nextIndex = displayedIndex >= this.props.testimonials.length - 1
                ? 0
                : displayedIndex + 1;
            this.setState({ displayedIndex: nextIndex });
        };
        this.showPrevious = () => {
            const { displayedIndex } = this.state;
            const nextIndex = displayedIndex <= 0
                ? this.props.testimonials.length - 1
                : displayedIndex - 1;
            this.setState({ displayedIndex: nextIndex });
        };
    }
    render() {
        return (React__default.createElement(Testimonial, Object.assign({}, this.props.testimonials[this.state.displayedIndex], { heading: React__default.createElement(React__default.Fragment, null,
                React__default.createElement(Subheading, null, this.props.subheading),
                React__default.createElement(SectionHeader, { "data-testid": "testimonials-header", style: { marginBottom: 30 } },
                    React__default.createElement(EmphasizedText, { text: this.props.heading, highlight: this.props.highlightedTitleFragments }))), actionButtons: React__default.createElement(ArrowsWrapper, null,
                React__default.createElement(ArrowLeftIcon, { onClick: this.showPrevious }),
                React__default.createElement(ArrowRightIcon, { onClick: this.showNext })) })));
    }
}
Testimonials.defaultProps = defaultProps;
const ArrowsWrapper = styled.div `
  display: flex;
  justify-content: center;

  > svg {
    margin: 10px;
    cursor: pointer;
    @media ${device.tablet} {
      margin: 0 20px 0 0;
    }
  }

  @media ${device.tablet} {
    justify-content: flex-start;
  }
`;

export { Testimonials };
