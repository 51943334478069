import React__default from 'react';

const HeadlessEcommerceIcon = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "Headless E-commerce Solutions icon"),
    React__default.createElement("defs", null,
        React__default.createElement("style", null, `
      .cls08-1 {
        fill: #bae1d3;
      }

      .cls08-2 {
        fill: none;
        stroke: #323433;
        stroke-width: 2px;
      }

      .cls08-3 {
        fill: #323433;
      }
      `)),
    React__default.createElement("g", { id: "ludziki" },
        React__default.createElement("rect", { className: "cls08-1", x: "6.26", y: "14.67", width: "43.86", height: "63.96" }),
        React__default.createElement("g", null,
            React__default.createElement("line", { className: "cls08-2", x1: "36.44", y1: "82.32", x2: "63.79", y2: "82.32" }),
            React__default.createElement("line", { className: "cls08-2", x1: "50.12", y1: "74.61", x2: "50.12", y2: "82.32" })),
        React__default.createElement("g", null,
            React__default.createElement("polygon", { className: "cls08-3", points: "66.12 39.33 61.6 58.56 50 58.56 50 56.56 60.02 56.56 63.6 41.33 34.34 41.33 33.87 39.33 66.12 39.33" }),
            React__default.createElement("polygon", { className: "cls08-3", points: "50 56.56 50 58.56 38.39 58.56 34.92 43.79 36.98 43.79 39.98 56.56 50 56.56" }),
            React__default.createElement("rect", { className: "cls08-3", x: "42.99", y: "44.91", width: "2", height: "9.54" }),
            React__default.createElement("rect", { className: "cls08-3", x: "49", y: "44.91", width: "2", height: "9.54" }),
            React__default.createElement("rect", { className: "cls08-3", x: "55.01", y: "44.91", width: "2", height: "9.54" }),
            React__default.createElement("rect", { className: "cls08-3", x: "49.25", y: "36.13", width: "14.42", height: "2", transform: "translate(-10.61 28.88) rotate(-26.31)" })),
        React__default.createElement("polyline", { className: "cls08-2", points: "74.76 74.61 10.47 74.61 10.47 18.68 89.76 18.68 89.76 60.97" }),
        React__default.createElement("circle", { className: "cls08-3", cx: "16.86", cy: "24.33", r: "0.99" }),
        React__default.createElement("circle", { className: "cls08-3", cx: "22.29", cy: "24.33", r: "0.99" }),
        React__default.createElement("circle", { className: "cls08-3", cx: "27.72", cy: "24.33", r: "0.99" }),
        React__default.createElement("polygon", { className: "cls08-2", points: "83.33 82.49 86.61 72.19 96.92 68.9 76.23 61.81 83.33 82.49" }))));
HeadlessEcommerceIcon.defaultProps = {
    "data-testid": "headless-ecommerce-icon",
};

export { HeadlessEcommerceIcon };
