import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { Confetti3x5 } from '../Icons/Confetti3x5.js';
import { ConfettiBig8x9 } from '../Icons/ConfettiBig8x9.js';

const ConfettiPageHeader = (props) => (React__default.createElement(OuterWrapper, null,
    React__default.createElement(Wrapper, null,
        React__default.createElement(Desktop, null,
            React__default.createElement(ConfettiBig8x9, null)),
        React__default.createElement(Mobile, { style: { alignSelf: "flex-start" } },
            React__default.createElement(Confetti3x5, { style: {
                    position: "absolute",
                    transform: "rotate(-90deg) translate(-5px, 5px)",
                    transformOrigin: "bottom",
                } })),
        React__default.createElement(ContentWrapper, null, props.children),
        React__default.createElement(Desktop, null,
            React__default.createElement(ConfettiBig8x9, { style: { transform: "scaleX(-1)" } })),
        React__default.createElement(Mobile, { style: { alignSelf: "flex-end" } },
            React__default.createElement(Confetti3x5, { style: {
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    transform: "translate(45px, -10px) rotate(90deg) scaleX(-1)",
                    transformOrigin: "top",
                } })))));
const OuterWrapper = styled.div `
  width: 100%;
  min-height: 370px;
  text-align: center;
  margin-bottom: 200px;
`;
const Wrapper = styled.div `
  min-height: 370px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: ${spacing.maxWidth}px;
  > * {
    height: 100%;
  }
`;
const ContentWrapper = styled.div `
  flex: 1;
`;
const Mobile = styled.div `
  @media ${device.desktop} {
    visibility: hidden;
    display: none;
  }
`;
const Desktop = styled.div `
  @media ${device.mobile} {
    display: none;
  }
`;

export { ConfettiPageHeader };
