import React__default from 'react';

const EndToEndIcon = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "End to End Delivery icon"),
    React__default.createElement("defs", null,
        React__default.createElement("style", null, `
      .cls05-1 {
        fill: #bae1d3;
      }

      .cls05-2, .cls05-3 {
        fill: none;
        stroke: #323433;
        stroke-width: 2px;
      }

      .cls05-3 {
        stroke-linecap: square;
      }

      .cls05-4 {
        fill: #323433;
      }
      `)),
    React__default.createElement("g", { id: "ludziki" },
        React__default.createElement("g", null,
            React__default.createElement("g", null,
                React__default.createElement("rect", { className: "cls05-1", x: "55.61", y: "23.69", width: "17.38", height: "17.38" }),
                React__default.createElement("rect", { className: "cls05-1", x: "55.61", y: "41.07", width: "17.38", height: "17.38" }),
                React__default.createElement("rect", { className: "cls05-1", x: "38.23", y: "23.69", width: "17.38", height: "17.38" })),
            React__default.createElement("polyline", { className: "cls05-2", points: "81.67 23.7 81.67 15.01 64.3 15.01 64.3 32.38 81.67 32.38" }),
            React__default.createElement("line", { className: "cls05-2", x1: "81.67", y1: "32.38", x2: "81.67", y2: "23.69" }),
            React__default.createElement("polyline", { className: "cls05-2", points: "64.3 36.73 64.3 49.76 81.67 49.76 81.67 32.38 64.3 32.38" }),
            React__default.createElement("polyline", { className: "cls05-2", points: "64.3 32.38 64.3 15.01 46.92 15.01 46.92 32.38 59.95 32.38" }),
            React__default.createElement("line", { className: "cls05-3", x1: "64.29", y1: "68.92", x2: "77.87", y2: "68.92" }),
            React__default.createElement("line", { className: "cls05-3", x1: "64.29", y1: "74.88", x2: "81.67", y2: "74.88" }),
            React__default.createElement("line", { className: "cls05-3", x1: "64.29", y1: "80.83", x2: "74.85", y2: "80.83" }),
            React__default.createElement("polyline", { className: "cls05-2", points: "44.01 69.6 44.01 77.11 38.79 82.33" }),
            React__default.createElement("polyline", { className: "cls05-2", points: "20.13 82.33 20.13 60.92 28.84 52.21" }),
            React__default.createElement("polyline", { className: "cls05-2", points: "42.05 88.21 42.05 82.33 18.33 82.33 18.33 88.21" }),
            React__default.createElement("path", { className: "cls05-1", d: "M54.61,42.07V68.73H36.05a12.17,12.17,0,0,0-.63-2.68L37,64.52A6,6,0,0,0,37,56L36,55.11,28,63.21V42.07Z" }),
            React__default.createElement("path", { className: "cls05-4", d: "M55.61,41.07V69.73H36.11a10.78,10.78,0,0,0-.23-2H53.61V43.07H29V62.21l-2,2V41.07Z" }),
            React__default.createElement("path", { className: "cls05-2", d: "M24.49,66.66,36,55.11,37,56a6,6,0,0,1,0,8.49l-1.53,1.53S39,75.26,29.86,75.8" })))));
EndToEndIcon.defaultProps = {
    "data-testid": "end-to-end-icon",
};

export { EndToEndIcon };
