import React__default from 'react';

const DevelopersIcon = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "100", height: "100", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "Laptop icon"),
    React__default.createElement("g", { fill: "none", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 0h100v100H0z" }),
        React__default.createElement("path", { fill: "#BAE1D3", d: "M57 73h33V15H57zM57 84h40V73H57z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M10 74h77V18H10v56zm2-2V20h73v52H12z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M2 81h92v-9H2v9zm2-2v-5h88v5H4z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M40 77.5h16v-2H40zM40.689 37.725l-1.378-1.45L28.548 46.5l10.763 10.225 1.378-1.45-9.237-8.775z" }),
        React__default.createElement("path", { fill: "#323433", fillRule: "nonzero", d: "M55.311 55.275l1.378 1.45L67.452 46.5 56.689 36.275l-1.378 1.45 9.237 8.775z" }),
        React__default.createElement("g", { fill: "#323433" },
            React__default.createElement("path", { d: "M42 46.537a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0M46.933 46.537a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0M51.866 46.537a1.25 1.25 0 1 0 2.5 0 1.25 1.25 0 0 0-2.5 0" })))));
DevelopersIcon.defaultProps = {
    "data-testid": "icon-benefits-small",
};

export { DevelopersIcon };
