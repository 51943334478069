import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { ThumbnailHeader } from '../typography.js';
import '../ButtonPrimary/ButtonPrimary.js';
import { B as ButtonInlineLink } from '../../../InlineButton-78cf1369.js';
import { ArrowRightIcon } from '../Icons/ArrowRight.js';

const PostThumbnail = ({ title, url, elementWithBackgroundImage, }) => (React__default.createElement(Wrapper, { "data-testid": "post-thumbnail" },
    React__default.createElement(ThumbnailImageWrapper, { "data-testid": `${url}-img` }, elementWithBackgroundImage),
    React__default.createElement(ContentWrapper, null,
        React__default.createElement(ThumbnailHeader, { href: url, "data-testid": "post-link", as: "a", target: "_blank", rel: "noopener" }, title),
        React__default.createElement(ButtonInlineLink, { as: "a", href: url, target: "_blank", rel: "noopener", style: {
                display: "flex",
                alignItems: "center",
            } },
            React__default.createElement(ArrowRightIcon, { style: { marginRight: 10 } }),
            "Read now"))));
const Wrapper = styled.div `
  position: relative;
  display: block;
  text-decoration: none;
  width: 307px;
  max-height: 440px;

  @media ${device.tablet} {
    width: 360px;
  }
`;
const ThumbnailImageWrapper = styled.div `
  > * {
    height: 270px;
    width: 100%;

    background-size: cover;
    background-position: center;
  }
`;
const ContentWrapper = styled.div `
  text-decoration: none;
  padding: 30px;
  position: relative;
  top: -84px;
  left: 40px;
  width: calc(100% - 100px);
  background-color: white;
  display: block;

  @media ${device.mobile} {
    padding-right: 0;
    width: calc(100% - 70px);
  }
`;

export { PostThumbnail };
