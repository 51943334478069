import React__default from 'react';
import styled from 'styled-components';

const MaintenanceIcon = (props) => (React__default.createElement(Icon, Object.assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100" }, props),
    React__default.createElement("polygon", { className: "cls-2", points: "93 60 73 60 71 60 66 60 66 62 71 62 71 76 82 76 82 81 84 81 84 74 73 74 73 62 93 62 93 81 95 81 95 62 95 60 93 60" }),
    React__default.createElement("g", null,
        React__default.createElement("path", { className: "cls-1", d: "M100,58c0-7.73-6.27-14-14-14s-14,6.27-14,14h28Z" }),
        React__default.createElement("path", { className: "cls-2", d: "M99,60h-2v-1c0-7.17-5.83-13-13-13s-13,5.83-13,13v1h-2v-1c0-8.27,6.73-15,15-15s15,6.73,15,15v1Z" })),
    React__default.createElement("rect", { className: "cls-1", x: "6", y: "18", width: "41", height: "61" }),
    React__default.createElement("rect", { className: "cls-2", x: "34", y: "80", width: "26", height: "2" }),
    React__default.createElement("rect", { className: "cls-2", x: "46", y: "74.67", width: "2", height: "6.06" }),
    React__default.createElement("polygon", { className: "cls-2", points: "60 76 9 76 9 21 85 21 85 39 83 39 83 23 11 23 11 74 60 74 60 76" }),
    React__default.createElement("circle", { className: "cls-2", cx: "16.03", cy: "27.14", r: ".93" }),
    React__default.createElement("circle", { className: "cls-2", cx: "21.16", cy: "27.14", r: ".93" }),
    React__default.createElement("circle", { className: "cls-2", cx: "26.29", cy: "27.14", r: ".93" }),
    React__default.createElement("path", { className: "cls-2", d: "M47,54c-3.31,0-6-2.69-6-6s2.69-6,6-6,6,2.69,6,6-2.69,6-6,6Zm0-10c-2.21,0-4,1.79-4,4s1.79,4,4,4,4-1.79,4-4-1.79-4-4-4Z" }),
    React__default.createElement("g", null,
        React__default.createElement("path", { className: "cls-2", d: "M47,63.61h-4.14v-3.84c-1.45-.51-2.81-1.29-3.98-2.31l-3.35,1.93-4.14-7.17,3.34-1.93c-.15-.74-.22-1.51-.22-2.3s.07-1.56,.22-2.3l-3.34-1.93,4.14-7.19,3.33,1.93c1.37-1.17,2.99-2.04,4.72-2.52l.54,1.93c-1.66,.46-3.19,1.34-4.43,2.55l-.54,.53-2.89-1.67-2.14,3.72,2.92,1.69-.21,.75c-.22,.79-.33,1.64-.33,2.53s.11,1.74,.33,2.53l.21,.75-2.92,1.68,2.14,3.7,2.91-1.68,.54,.54c1.23,1.22,2.76,2.1,4.41,2.56l.73,.2v3.34h2.14v2Z" }),
        React__default.createElement("path", { className: "cls-2", d: "M50.41,60l-.54-1.93c1.67-.47,3.16-1.33,4.41-2.56l.54-.54,2.91,1.68,2.14-3.7-2.89-1.68,.19-.73c.21-.82,.31-1.67,.31-2.55s-.11-1.74-.33-2.53l-.21-.75,2.92-1.69-2.14-3.72-2.89,1.67-.54-.53c-1.24-1.2-2.77-2.08-4.43-2.55l-.73-.2v-3.34h-2.14v-2h4.14v3.84c1.46,.51,2.82,1.29,3.99,2.29l3.33-1.93,4.14,7.19-3.34,1.93c.15,.74,.22,1.51,.22,2.3s-.07,1.55-.21,2.29l3.33,1.93-4.14,7.16-3.35-1.93c-1.37,1.19-2.98,2.06-4.71,2.54Z" }))));
const Icon = styled.svg `
  .cls-1{fill:#bae1d3;}.cls-2{fill:#323433;}
`;
MaintenanceIcon.defaultProps = {
    "data-testid": "icon-maintenance",
};

export { MaintenanceIcon };
