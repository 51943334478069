import styled from 'styled-components';
import { s as spacing } from '../../spacing-ad86e0c5.js';
import { d as device } from '../../device-db5dbdea.js';
import { T as TextBlack, A as AcceptanceGreen, a as TextMedium } from '../../colors-c52fac99.js';

const maxColumnWidth = "457px";
const Header = styled.h1 `
  width: 100%;
  font-family: "HK Grotesk", Helvetica, sans-serif;
  font-weight: bold;
  letter-spacing: -0.6px;
  max-width: ${maxColumnWidth};
  text-align: center;

  font-family: ${(props) => props.font || "inherit"};
  color: ${(props) => props.color || "rgba(60, 63, 62, 1.0)"};

  @media ${device.tablet} {
    text-align: left;
  }
`;
const PageHeader = styled(Header) `
  font-size: 60px;
  letter-spacing: -0.43px;
  color: ${TextBlack};

  @media ${device.mobile} {
    width: 100%;
    background: #ffffff;
    position: relative;
    font-size: 45px;
    letter-spacing: -0.57px;
  }
`;
const SectionHeader = styled(Header) `
  font-size: 48px;
  letter-spacing: -0.46px;
  color: ${TextBlack};

  @media ${device.mobile} {
    font-size: 36px;
    letter-spacing: -0.34px;
  }
`;
const SectionHeaderSmall = styled(SectionHeader) `
  font-size: 36px;
  letter-spacing: -0.34px;

  @media ${device.mobile} {
    font-size: 27px;
    letter-spacing: -0.25px;
  }
`;
const ThumbnailHeader = styled(SectionHeader) `
  text-decoration: none;
  display: block;
  font-size: 24px !important;
  font-weight: bold;
  letter-spacing: -0.2px;
  text-align: left;

  :before {
    content: "";
    display: block;
    margin-bottom: ${spacing.mobile.padding.default}px;
    width: ${spacing.mobile.padding.default}px;
    border-bottom: 4px solid ${AcceptanceGreen};
  }
`;
const Subheading = styled(Header) `
  font-size: 12px;
  letter-spacing: 0.55px;
  color: ${AcceptanceGreen};
`;
const TextBody = styled.p `
  text-align: left;
  max-width: ${maxColumnWidth};
  font-family: "HK Grotesk", Helvetica, sans-serif;
  font-size: ${(props) => props.fontSize || "18px"};
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: ${(props) => props.color || TextMedium};
  ${(props) => (props.bold ? "font-weight: bold" : "")};
  font-family: ${(props) => props.font || "inherit"};
  margin-top: 16px;
`;

export { Header, PageHeader, SectionHeader, SectionHeaderSmall, Subheading, TextBody, ThumbnailHeader, maxColumnWidth };
