import React__default, { Component } from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import '../typography.js';
import { ButtonPrimaryLink } from '../ButtonPrimary/ButtonPrimary.js';
import '../Icons/TwitterIcon.js';
import '../Icons/LinkedInIcon.js';
import '../Icons/YoutubeIcon.js';
import { SocialIcons } from '../SocialIcons/SocialIcons.js';
import { MenuItem, MenuItemWrapper } from './MenuItem.js';
import { MenuButtons } from './MenuButtons.js';
import { MenuItemsWrapper } from './MenuItemsWrapper.js';
import { MenuToggleButton } from './MenuToggleButton.js';

const elementDelay = 0.1;
class HeaderMobile extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            menuOpen: this.props.menuOpen || false,
        };
        this.closeMenu = () => this.setState({ menuOpen: !this.state.menuOpen });
    }
    render() {
        const { items, socialIcons, logo, primaryButtonElement, hideSublinksForMobile } = this.props;
        const mobileItems = items.filter((item) => item.hideOnMobile !== true);
        return (React__default.createElement(Wrapper, null,
            React__default.createElement(MenuButtons, null,
                logo,
                React__default.createElement(MenuToggleButton, { open: this.state.menuOpen, onClick: this.closeMenu })),
            React__default.createElement(MenuItemsWrapper, { open: this.state.menuOpen },
                React__default.createElement(ScrollableWrapper, { open: this.state.menuOpen }, this.state.menuOpen && (React__default.createElement(React__default.Fragment, null, mobileItems.map((item, index) => (React__default.createElement(MenuItem, Object.assign({ key: item.name, style: { animationDelay: `${index * elementDelay}s` }, closeMenu: this.closeMenu, hideSublinks: hideSublinksForMobile }, item))))))),
                this.state.menuOpen && (React__default.createElement(MobileMenuBottom, { style: {
                        animationDelay: `${(mobileItems.length + 2) * elementDelay}s`,
                    } },
                    React__default.createElement(SocialIcons, Object.assign({}, socialIcons)),
                    primaryButtonElement ? (primaryButtonElement) : (React__default.createElement(ButtonPrimaryLink, { as: "a", href: "/contact-us" }, "Request consultation")))))));
    }
}
const Wrapper = styled.div `
  width: 100%;

  @media ${device.desktop} {
    visibility: hidden;
    display: none;
  }
`;
const ScrollableWrapper = styled(MenuItemWrapper) `
  overflow: scroll;
  padding-bottom: ${(props) => (props.open ? 200 : 0)};
  height: ${(props) => props.open ? `calc(100vh - 170px)` : 0};
`;
const MobileMenuBottom = styled(MenuItemWrapper) `
  z-index: 2;
  background-color: white;
  position: fixed;
  width: calc(100vw - ${2 * spacing.mobile.padding.default}px);
  padding: ${spacing.mobile.padding.default}px 0;
  margin: 0 ${spacing.mobile.padding.default}px;
  bottom: 0;
  left: 0;
  border-top: 1px solid #d8dcdb;
  display: flex;
  justify-content: space-between;
`;

export { HeaderMobile, ScrollableWrapper };
