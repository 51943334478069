import React__default, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { s as spacing } from './spacing-ad86e0c5.js';
export { s as bA } from './spacing-ad86e0c5.js';
import { d as device } from './device-db5dbdea.js';
export { d as by, b as bz } from './device-db5dbdea.js';
export { c as k } from './colors-c52fac99.js';
export { Header as a4, PageHeader as a5, SectionHeader as a6, SectionHeaderSmall as a7, ThumbnailHeader as a8, Subheading as a9, TextBody as aa } from './src/components/typography.js';
export { Emphasis as ae } from './src/components/Emphasis/Emphasis.js';
export { EmphasizedText as af } from './src/components/EmphasizedText/EmphasizedText.js';
import { CenteredContentWrapper } from './src/components/layout/CenteredContentWrapper.js';
export { CenteredContentWrapper as O } from './src/components/layout/CenteredContentWrapper.js';
export { Hero as H } from './src/components/Hero/Hero.js';
export { ButtonPrimary as ab, ButtonPrimaryLink as ac } from './src/components/ButtonPrimary/ButtonPrimary.js';
export { HeroNew as l } from './src/components/Hero/HeroNew.js';
export { Confetti5Rows as aB } from './src/components/Icons/Confetti5Rows.js';
export { ThreeUpCollage as p } from './src/components/ThreeUpCollage/ThreeUpCollage.js';
export { B as ad } from './InlineButton-78cf1369.js';
export { Services as q } from './src/components/Services/Services.js';
export { ArrowRightIcon as ah } from './src/components/Icons/ArrowRight.js';
export { LatestPosts as L } from './src/components/LatestPosts/LatestPosts.js';
export { Confetti3x5 as aC } from './src/components/Icons/Confetti3x5.js';
export { Confetti7Rows as aD } from './src/components/Icons/Confetti7Rows.js';
export { ArrowLeftIcon as ai } from './src/components/Icons/ArrowLeft.js';
export { Testimonials as r } from './src/components/Testimonials/Testimonials.js';
export { TextToutFullwidth as s } from './src/components/TextToutFullWidth/TextToutFullwidth.js';
import { XolvioLogoVertical } from './src/components/Icons/XolvioLogoVertical.js';
export { XolvioLogoVertical as ax } from './src/components/Icons/XolvioLogoVertical.js';
import TwitterIcon from './src/components/Icons/TwitterIcon.js';
export { TwitterIcon as aX } from './src/components/Icons/TwitterIcon.js';
import LinkedInIcon from './src/components/Icons/LinkedInIcon.js';
export { LinkedInIcon as aV } from './src/components/Icons/LinkedInIcon.js';
import YoutubeIcon from './src/components/Icons/YoutubeIcon.js';
export { YoutubeIcon as aW } from './src/components/Icons/YoutubeIcon.js';
export { SocialIcons as aY } from './src/components/SocialIcons/SocialIcons.js';
export { EnvelopeIcon as aJ } from './src/components/Icons/EnvelopeIcon.js';
import { LinksGroup, LegaleseWrapper, LegalLink, CopyrightNotice } from './src/components/Footer/Footer.js';
export { Footer as t } from './src/components/Footer/Footer.js';
import { IconTout } from './src/components/IconTout/IconTout.js';
export { ToutImageConfettiWrapper as a2 } from './src/components/ImageTout/ToutImageConfettiWrapper.js';
import { ImageTout } from './src/components/ImageTout/ImageTout.js';
export { ToutGroupOld as u } from './src/components/ToutGroup/ToutGroupOld.js';
export { XolvioLogo as ay } from './src/components/Icons/XolvioLogo.js';
export { defaultHeaderProps as bC } from './src/components/Header/defaultHeaderProps.js';
export { Menu as M } from './src/components/Header/Header.js';
export { defaultHeaderPropsNew as bD } from './src/components/Header/defaultHeaderPropsNew.js';
export { Clients as C } from './src/components/Clients/Clients.js';
export { UserIcon as b5 } from './src/components/Icons/UserIcon.js';
export { ContactUs as m } from './src/components/ContactForms/ContactUs/ContactUs.js';
export { Loader as K } from './src/components/Loader/Loader.js';
export { Spacing as a0 } from './src/components/layout/Spacing.js';
export { ContactUsNew as n } from './src/components/ContactForms/ContactUsNew/ContactUsNew.js';
export { CheckIcon as aH } from './src/components/Icons/CheckIcon.js';
export { CheckIconThin as aI } from './src/components/Icons/CheckIconThin.js';
export { ListChecked as z } from './src/components/ListChecked/ListChecked.js';
export { ContactUsSlim as o } from './src/components/ContactForms/ContactUsSlim/ContactUsSlim.js';
export { Confetti2x4 as aE } from './src/components/Icons/Confetti2x4.js';
export { ConfettiBigSectionWrapper as W } from './src/components/layout/ConfettiBigSectionWrapper.js';
export { SplitBackground as X } from './src/components/layout/SplitBackground.js';
export { TwoColumnsWithImage as Z } from './src/components/layout/TwoColumnsWithImage.js';
export { TwoColumnsWrapper as Y } from './src/components/layout/TwoColumns.js';
import { Quote } from './src/components/Quote/Quote.js';
export { Quote as Q } from './src/components/Quote/Quote.js';
import { QuoteNew } from './src/components/Quote/QuoteNew.js';
export { QuoteNew as v } from './src/components/Quote/QuoteNew.js';
export { Leaders as x } from './src/components/Leaders/Leaders.js';
export { Team as y } from './src/components/Team/Team.js';
export { ImageConfettiWrapper as _ } from './src/components/ImageConfettiWrapper/ImageConfettiWrapper.js';
export { Confetti6x8Big as aG } from './src/components/Icons/Confetti6x8Big.js';
export { ConfettiSectionWrapper as U } from './src/components/layout/ConfettiSectionWrapper.js';
export { ConfettiHR as V } from './src/components/layout/ConfettiHR.js';
export { ArrowCurveIcon as aj } from './src/components/Icons/ArrowCurve.js';
export { ArrowTopIcon as al } from './src/components/Icons/ArrowTop.js';
export { QualityFasterIcon as aw } from './src/components/Icons/QualityFasterIcon.js';
export { XSpecsIcon as az } from './src/components/Icons/XSpecsIcon.js';
export { ChimpIcon as aA } from './src/components/Icons/ChimpIcon.js';
export { StopwatchIcon as aT } from './src/components/Icons/StopwatchIcon.js';
export { ConfettiBig8x9 as aF } from './src/components/Icons/ConfettiBig8x9.js';
export { ShapesIcon as aK } from './src/components/Icons/Shapes.js';
export { ScreenIcon as aL } from './src/components/Icons/Screen.js';
export { ReadinessAssessmentIcon as aM } from './src/components/Icons/ReadinessAssessment.js';
export { SupergraphProjectRoadmapIcon as aN } from './src/components/Icons/SupergraphProjectRoadmap.js';
export { SchemaDesignSessionsIcon as aO } from './src/components/Icons/SchemaDesignSessions.js';
export { SupergraphIcon as aP } from './src/components/Icons/Supergraph.js';
export { IntegratingIcon as aQ } from './src/components/Icons/Integrating.js';
export { ReferenceRepositoriesIcon as aR } from './src/components/Icons/ReferenceRepositories.js';
export { MaintenanceIcon as aS } from './src/components/Icons/Maintenance.js';
export { FlipchartIcon as aU } from './src/components/Icons/Flipchart.js';
import { MediumIcon } from './src/components/Icons/MediumIcon.js';
export { MediumIcon as aZ } from './src/components/Icons/MediumIcon.js';
export { DigitalStrategyAndArchitectureShape as ag } from './src/components/Icons/DigitalStrategyAndArchitectureShape.js';
export { default as E } from './src/Icons/EditIcon.js';
export { default as R } from './src/Icons/RemoveIcon.js';
export { default as A } from './src/Icons/AddSuiteIcon.js';
export { default as c } from './src/Icons/AddSpecIcon.js';
export { default as d } from './src/Icons/SortIcon.js';
export { default as e } from './src/Icons/ArrowIcon.js';
export { default as P } from './src/components/Icons/PenIcon.js';
export { StarIcon as f } from './src/components/Icons/StarIcon.js';
export { SubjectTile as N } from './src/components/ContactForms/ContactUs/SubjectTile.js';
export { DigitalStrategyIcon as bE } from './src/components/Icons/Services/DigitalStrategy.js';
export { SolutionIdeationIcon as bF } from './src/components/Icons/Services/SolutionIdeation.js';
export { SystemsModernizationIcon as bG } from './src/components/Icons/Services/SystemsModernization.js';
export { ApolloGraphqlIcon as bH } from './src/components/Icons/Services/ApolloGraphql.js';
export { EndToEndIcon as bI } from './src/components/Icons/Services/EndToEnd.js';
export { StaffAugmentationIcon as bJ } from './src/components/Icons/Services/StaffAugmentation.js';
export { BespokeMartechIcon as bK } from './src/components/Icons/Services/BespokeMartech.js';
export { HeadlessEcommerceIcon as bL } from './src/components/Icons/Services/HeadlessEcommerce.js';
export { default as I } from './src/elements/Input/Input.js';
export { Button as B, ButtonSecondary as g } from './src/elements/Buttons/Button.js';
export { ActionViewButton as h } from './src/elements/Buttons/ActionViewButton.js';
export { EmbedActionButton as i } from './src/elements/Buttons/EmbedActionButton.js';
export { InlineButton as j } from './src/elements/Buttons/InlineButton.js';
export { BenefitsSmallIcon as a_ } from './src/components/Icons/BenefitsSmallIcon.js';
export { DesignersShapesIcon as a$ } from './src/components/Icons/DesignersShapesIcon.js';
export { FeaturesSmallIcon as b6 } from './src/components/Icons/FeaturesSmallIcon.js';
export { TestersIcon as b7 } from './src/components/Icons/TestersIcon.js';
export { DevelopersIcon as b1 } from './src/components/Icons/DevelopersIcon.js';
export { PhoneIcon as b2 } from './src/components/Icons/PhoneIcon.js';
export { BuildingsIcon as b3 } from './src/components/Icons/BuildingsIcon.js';
export { UsersIcon as b4 } from './src/components/Icons/UsersIcon.js';
export { ChimpLogo as b8 } from './src/components/Icons/ChimpLogo.js';
export { QualityFasterLogo as b9 } from './src/components/Icons/QualityFasterLogo.js';
export { XSpecsLogo as ba } from './src/components/Icons/XSpecsLogo.js';
export { ConfettiPageHeader as $ } from './src/components/layout/ConfettiPageHeader.js';
export { FlipchartSmallIcon as bb } from './src/components/Icons/FlipchartSmall.js';
export { ScreenSmallIcon as bc } from './src/components/Icons/ScreenSmallIcon.js';
export { StopwatchSmallIcon as bd } from './src/components/Icons/StopwatchSmallIcon.js';
export { TwoColumnContentNodeWrapper as a1 } from './src/components/layout/TwoColumnContentNodeWrapper.js';
export { TestimonialsNoImage as w } from './src/components/Testimonials/TestimonialsNoImage.js';
export { ShapesIconSmall as b0 } from './src/components/Icons/ShapesIconSmall.js';
export { ImageWrapper as a3 } from './src/elements/ImageWrapper.js';
export { StorybookLogo as bl } from './src/components/Icons/Logos/StorybookLogo.js';
export { BitBucketLogo as bm } from './src/components/Icons/Logos/BitBucketLogo.js';
export { GitHubLogo as bo } from './src/components/Icons/Logos/GitHubLogo.js';
export { FigmaLogo as bq } from './src/components/Icons/Logos/FigmaLogo.js';
export { FigmaLogoVertical as br } from './src/components/Icons/Logos/FigmaLogoVertical.js';
export { ZeplinLogo as bs } from './src/components/Icons/Logos/ZeplinLogo.js';
export { ZeplinLogoVertical as bt } from './src/components/Icons/Logos/ZeplinLogoVertical.js';
export { GitLabLogo as bu } from './src/components/Icons/Logos/GitLabLogo.js';
export { GitLabLogoVertical as bv } from './src/components/Icons/Logos/GitLabLogoVertical.js';
export { JiraLogo as bg } from './src/components/Icons/Logos/JiraLogo.js';
export { CircleCILogo as bh } from './src/components/Icons/Logos/CircleCILogo.js';
export { TravisLogo as bi } from './src/components/Icons/Logos/TravisLogo.js';
export { JenkinsLogo as bj } from './src/components/Icons/Logos/JenkinsLogo.js';
export { SketchLogo as bk } from './src/components/Icons/Logos/SketchLogo.js';
export { GitLogo as bf } from './src/components/Icons/Logos/GitLogo.js';
export { ConfluenceLogo as be } from './src/components/Icons/Logos/ConfluenceLogo.js';
export { ToutSmallFullwidth as D } from './src/components/ToutSmallFullWidth/ToutSmallFullwidth.js';
export { BusinessFolksSmallIcon as aq } from './src/components/Icons/BusinessFolksSmallIcon.js';
export { TerminalSmallIcon as an } from './src/components/Icons/TerminalSmallIcon.js';
export { DesignersSmallIcon as ap } from './src/components/Icons/DesignersSmallIcon.js';
export { QAsSmallIcon as ao } from './src/components/Icons/QAsSmallIcon.js';
export { BusinessFolksIcon as av } from './src/components/Icons/BusinessFolksIcon.js';
export { TerminalIcon as ar } from './src/components/Icons/TerminalIcon.js';
export { SpecsIcon as as } from './src/components/Icons/SpecsIcon.js';
export { DesignersIcon as au } from './src/components/Icons/DesignersIcon.js';
export { QAsIcon as at } from './src/components/Icons/QAsIcon.js';
export { ArrowBottomIcon as ak } from './src/components/Icons/ArrowBottom.js';
export { CloseIcon as am } from './src/components/Icons/CloseIcon.js';
export { XSpecsLogoVertical as bw } from './src/components/Icons/XSpecsLogoVertical.js';
export { GitHubLogoVertical as bp } from './src/components/Icons/Logos/GitHubLogoVertical.js';
export { BitBucketLogoVertical as bn } from './src/components/Icons/Logos/BitBucketLogoVertical.js';
export { Modal as J } from './src/components/Modal/Modal.js';
export { default as G } from './src/components/SubscriptionInput/SubscriptionInput.js';
export { SquareIconWithNumber as bx } from './src/components/SquareIconWithNumber/SquareIconWithNumber.js';

const SocialIconsNew = ({ youtube = "https://www.youtube.com/c/XolvIo/videos", twitter = "https://twitter.com/xolvio", linkedin = "https://www.linkedin.com/company/xolvio/", medium = 'https://medium.xolv.io/' }) => (React__default.createElement(SocialIconsWrapper, null,
    linkedin && (React__default.createElement("a", { href: linkedin, target: "_blank", "aria-label": "Xolvio linkedin profile" },
        React__default.createElement(LinkedInIcon, null))),
    twitter && (React__default.createElement("a", { href: twitter, target: "_blank", "aria-label": "Xolvio twitter profile" },
        React__default.createElement(TwitterIcon, null))),
    medium && (React__default.createElement("a", { href: medium, target: "_blank", "aria-label": "Xolvio medium profile" },
        React__default.createElement(MediumIcon, null))),
    youtube && (React__default.createElement("a", { href: youtube, target: "_blank", "aria-label": "Xolvio youtube profile" },
        React__default.createElement(YoutubeIcon, null)))));
const SocialIconsWrapper = styled.div `
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: ${spacing.mobile.padding.default}px;
  }
`;

const FooterNew = ({ linkGroups, legaleseLinks, socialLinks, }) => {
    return (React__default.createElement(CenteredContentWrapper, null,
        React__default.createElement(FooterWrapper, null,
            React__default.createElement(LinksWrapper, null,
                React__default.createElement(LogoWrapper, null,
                    React__default.createElement(XolvioLogoVertical, { onClick: () => (window.location.href = "/"), style: { cursor: "pointer" } })),
                linkGroups.map((linksGroup) => (React__default.createElement(LinksGroup, Object.assign({}, linksGroup, { key: linksGroup.name }))))),
            React__default.createElement(SocialWrapper, null,
                React__default.createElement(SocialLinks, null,
                    React__default.createElement(SocialIconsNew, Object.assign({}, socialLinks)))),
            React__default.createElement(LegaleseWrapper, null,
                legaleseLinks.map((link) => (React__default.createElement(LegalLink, { onClick: link.onClick, href: link.onClick ? undefined : link.url, key: link.url, style: {
                        letterSpacing: "-0.1px",
                        color: "#d8dcdb",
                    } }, link.name))),
                React__default.createElement(CopyrightNotice, null, `${new Date().getFullYear()} Xolvio, Inc., All Rights Reserved. Xolvio, XSpecs and Quality Faster are registered trademarks of Xolvio, Inc.`)))));
};
FooterNew.defaultProps = {
    linkGroups: [],
};
const SocialWrapper = styled.div `
  h1 {
    width: 100%;
    text-align: left;
  }

  @media ${device.tabletVertical} {
    h1 {
      width: auto;
      margin-right: 20px;
    }
    align-items: end;
  }
  @media ${device.desktop} {
    display: flex;
    margin-left: 32px;
    padding-left: 32px;
    justify-content: end;
    align-items: end;
  }
  margin-bottom: ${spacing.mobile.padding.default}px;
`;
const LogoWrapper = styled.div `
  @media ${device.mobile} {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }
`;
const LinksWrapper = styled.div `
  h1 {
    margin-bottom: 8px;
  }

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: ${2 * spacing.mobile.padding.default}px;
  margin-bottom: ${2 * spacing.mobile.padding.default}px;

  * {
    text-align: left !important;
  }

  @media ${device.desktop} {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
`;
const SocialLinks = styled.div `
  display: flex;
  padding: 50px 0 20px;

  @media ${device.mobile} {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  @media ${device.tabletVertical} {
    h1 {
      margin-bottom: 24px;
    }
  }

  @media ${device.desktop} {
    flex-direction: row;
    padding: 20px 0 0 0;
    h1 {
      margin-bottom: 0px;
    }
  }
`;
const FooterWrapper = styled.div `
  margin-top: ${spacing.mobile.padding.default}px;
  
  @media ${device.desktop} {
    display: grid;
    grid-template-columns: 90% 1fr;
  }
`;

const ToutGroup = (props) => {
    return (React__default.createElement(CenteredContentWrapper, { style: props.style, className: "tout-group", id: props.id },
        React__default.createElement(IconToutsWrapper, null,
            React__default.createElement(IconTout, Object.assign({}, props.iconTout1)),
            props.iconTout2 && React__default.createElement(IconTout, Object.assign({}, props.iconTout2)),
            props.quote && (React__default.createElement(Wrapper, null,
                React__default.createElement(Quote, Object.assign({}, props.quote))))),
        props.imageTout && React__default.createElement(ImageTout, Object.assign({}, props.imageTout))));
};
const IconToutsWrapper = styled.div `
  display: flex;
  flex-direction: column;

  padding: 20px 0;

  @media ${device.mobile} {
    align-items: center;
    > *:nth-child(1) {
      margin-bottom: -20px;
    };
    > *:nth-child(2) {
      margin-bottom: -60px;
    }
  }

  @media ${device.tablet} {
    padding: 0;
    flex-direction: row;
    > * {
      width: 50%;

      // that's ugly but hard to achieve another way
      // it adds margin to second tout icon, so its text content is
      // aligned with the content of the image tout below
      :not(:nth-child(1)) {
        > * {
          :nth-child(1) {
            margin-left: ${spacing.mobile.padding.default}px;
          }
        }
      }
    }
  }
  @media ${device.tablet} {
    align-items: start;
  }
`;
const Wrapper = styled.div `
  display: flex;
  align-items: center;

  @media ${device.desktop} {
    padding: 0;
  }
`;

const ToutGroupNew = (props) => {
    return (React__default.createElement(CenteredContentWrapper, { style: props.style, className: "tout-group", id: props.id },
        React__default.createElement(IconToutsWrapper$1, null,
            React__default.createElement(IconTout, Object.assign({}, props.iconTout1)),
            props.iconTout2 && React__default.createElement(IconTout, Object.assign({}, props.iconTout2)),
            props.quote && (React__default.createElement(Wrapper$1, null,
                React__default.createElement(QuoteNew, Object.assign({}, props.quote))))),
        props.imageTout && React__default.createElement(ImageTout, Object.assign({}, props.imageTout))));
};
const IconToutsWrapper$1 = styled.div `
  display: flex;
  flex-direction: column;

  padding: 20px 0;

  @media ${device.mobile} {
    > *:nth-child(1) {
      margin-bottom: 40px;
    }
  }

  @media ${device.tablet} {
    padding: 0;
    flex-direction: row;
    > * {
      width: 50%;

      // that's ugly but hard to achieve another way
      // it adds margin to second tout icon, so its text content is
      // aligned with the content of the image tout below
      :not(:nth-child(1)) {
        > * {
          :nth-child(1) {
            margin-left: ${spacing.mobile.padding.default}px;
          }
        }
      }
    }
  }
`;
const Wrapper$1 = styled.div `
  display: flex;
  //align-items: center;

  @media ${device.desktop} {
    margin-left: -4px;
    padding: 0;
  }
  @media ${device.tabletVertical} {
    margin-left: -10px;
  }
`;

// Hook
const useWindowSize = () => {
    const isClient = typeof window === "object";
    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        };
    }
    const [windowSize, setWindowSize] = useState(getSize);
    useEffect(() => {
        if (!isClient) {
            return undefined;
        }
        function handleResize() {
            setWindowSize(getSize());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return windowSize;
};

export { FooterNew as F, SocialIconsNew as S, ToutGroup as T, SocialIconsWrapper as a, ToutGroupNew as b, useWindowSize as bB };
