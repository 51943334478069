import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device, b as breakpoints } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { Subheading, SectionHeader } from '../typography.js';
import '../Emphasis/Emphasis.js';
import { EmphasizedText } from '../EmphasizedText/EmphasizedText.js';
import '../Icons/Confetti4Rows.js';
import { Bio } from '../Bio/Bio.js';

const Leaders = (props) => (React__default.createElement(Wrapper, null,
    React__default.createElement("div", null,
        React__default.createElement(Subheading, null, props.subheading),
        React__default.createElement(Header, null,
            React__default.createElement(EmphasizedText, { text: props.title, highlight: "leaders" })),
        React__default.createElement(Desktop, null,
            React__default.createElement(Bio, Object.assign({}, props.profiles[1], { flipConfetti: true }))),
        React__default.createElement(Mobile, { style: { marginBottom: 45 } },
            React__default.createElement(Bio, Object.assign({}, props.profiles[0])))),
    React__default.createElement("div", null,
        React__default.createElement(Desktop, null,
            React__default.createElement(Bio, Object.assign({}, props.profiles[0]))),
        React__default.createElement(Mobile, null,
            React__default.createElement(Bio, Object.assign({}, props.profiles[1]))))));
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;

  @media ${device.tabletVertical} {
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 80px;
  }
`;
const Header = styled(SectionHeader) `
  margin-bottom: 45px;
`;
const Mobile = styled.div `
  @media ${device.tabletVertical} {
    visibility: hidden;
    display: none;
  }
`;
const Desktop = styled.div `
  @media (max-width: ${breakpoints.tabletVertical.start - 1}px) {
    display: none;
  }
`;

export { Leaders };
