import React__default from 'react';

const StaffAugmentationIcon = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "Staff Augmentation icon"),
    React__default.createElement("defs", null,
        React__default.createElement("style", null, `
      .cls06-1 {
        fill: #bae1d3;
      }

      .cls06-2, .cls06-3 {
        fill: none;
        stroke: #323433;
        stroke-width: 2px;
      }

      .cls06-3 {
        stroke-linecap: square;
      }
      `)),
    React__default.createElement("g", { id: "Layer_2", "data-name": "Layer 2" },
        React__default.createElement("g", null,
            React__default.createElement("path", { className: "cls06-1", d: "M84.49,19.74V30a9,9,0,0,1-4.68,1,9.15,9.15,0,0,1-7.91-7.06c-.34-1.77-.26-3.79-1.55-5-1.72-1.68-4.55-.73-7-1a6.68,6.68,0,0,1-4.24-2.23l0-.06a13,13,0,0,1,25.39,4.16Z" }),
            React__default.createElement("path", { className: "cls06-1", d: "M15.51,61.27V71.53a9,9,0,0,0,4.68,1,9.15,9.15,0,0,0,7.91-7.06c.34-1.77.26-3.79,1.55-5,1.72-1.68,4.55-.73,7-1a6.68,6.68,0,0,0,4.24-2.23l0-.06a13,13,0,0,0-25.39,4.15Z" }),
            React__default.createElement("g", null,
                React__default.createElement("path", { className: "cls06-2", d: "M81,33.51a8.74,8.74,0,0,1-4.68,1,9.13,9.13,0,0,1-7.91-7.06c-.35-1.76-.27-3.79-1.55-5-1.72-1.68-4.56-.73-7-1a6.71,6.71,0,0,1-4.24-2.24" }),
                React__default.createElement("g", null,
                    React__default.createElement("path", { className: "cls06-3", d: "M54.91,23.25a13,13,0,1,1,26.07,0" }),
                    React__default.createElement("polyline", { className: "cls06-3", points: "68.43 45.67 68.43 40.28 54.91 40.28 54.91 23.25" }),
                    React__default.createElement("line", { className: "cls06-3", x1: "80.98", y1: "23.25", x2: "80.98", y2: "45.67" }))),
            React__default.createElement("polyline", { className: "cls06-3", points: "68.43 45.67 68.43 40.28 54.91 40.28 54.91 23.25" }),
            React__default.createElement("g", null,
                React__default.createElement("path", { className: "cls06-2", d: "M19,75a8.77,8.77,0,0,0,4.68,1,9.15,9.15,0,0,0,7.92-7.06c.34-1.77.26-3.79,1.55-5,1.72-1.67,4.55-.72,7-1a6.76,6.76,0,0,0,4.23-2.24" }),
                React__default.createElement("g", null,
                    React__default.createElement("path", { className: "cls06-3", d: "M45.09,64.78a13,13,0,0,0-26.07,0" }),
                    React__default.createElement("polyline", { className: "cls06-3", points: "31.57 87.21 31.57 81.81 45.09 81.81 45.09 64.78" }),
                    React__default.createElement("line", { className: "cls06-3", x1: "19.02", y1: "64.78", x2: "19.02", y2: "87.21" }))),
            React__default.createElement("polyline", { className: "cls06-2", points: "80.98 45.67 80.98 81.76 54.91 81.76" }),
            React__default.createElement("polyline", { className: "cls06-2", points: "19.02 49.41 19.02 26.31 45.09 26.31" })))));
StaffAugmentationIcon.defaultProps = {
    "data-testid": "staff-augmentation-icon",
};

export { StaffAugmentationIcon };
