import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import '../typography.js';
import '../ButtonPrimary/ButtonPrimary.js';
import '../../../InlineButton-78cf1369.js';
import '../Icons/ArrowRight.js';
import { PostThumbnail } from '../PostThumbnail/PostThumbnail.js';

const LatestPosts = ({ posts }) => (React__default.createElement(Wrapper, null, posts.slice(0, 3).map((post) => (React__default.createElement(PostThumbnail, Object.assign({}, post, { key: post.url }))))));
const Wrapper = styled.div `
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  /* hide scrollbar */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    height: 0;
    width: 0;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex: 0 0 auto;
    text-align: left;

    :not(:last-child) {
      margin-right: 20px;

      @media ${device.desktop} {
        margin-right: 30px;
      }
    }
  }
`;

export { LatestPosts };
