import React__default from 'react';

const SystemsModernizationIcon = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "Systems Modernization icon"),
    React__default.createElement("defs", null,
        React__default.createElement("style", null, `
      .cls03-1 {
        fill: #bae1d3;
      }

      .cls03-2 {
        fill: none;
        stroke: #323433;
        stroke-width: 2px;
      }

      .cls03-3 {
        fill: #323433;
      }
          `)),
    React__default.createElement("g", { id: "Layer_2", "data-name": "Layer 2" },
        React__default.createElement("g", null,
            React__default.createElement("path", { className: "cls03-1", d: "M36.4,44.17a22,22,0,0,0,0,44" }),
            React__default.createElement("rect", { className: "cls03-1", x: "54.53", y: "11.79", width: "36.27", height: "36.27" }),
            React__default.createElement("polyline", { className: "cls03-2", points: "36.4 41.4 36.4 29.92 72.67 29.92 72.67 66.19 36.4 66.19 36.4 48.06" }),
            React__default.createElement("path", { className: "cls03-2", d: "M53.77,61a18.16,18.16,0,1,0,.76,5.23" }),
            React__default.createElement("path", { className: "cls03-2", d: "M27.33,11.79V29.92H9.2A18.13,18.13,0,0,1,27.33,11.79Z" }),
            React__default.createElement("circle", { className: "cls03-3", cx: "61.44", cy: "78.47", r: "1.68" }),
            React__default.createElement("circle", { className: "cls03-3", cx: "71.84", cy: "78.47", r: "1.68" }),
            React__default.createElement("circle", { className: "cls03-3", cx: "82.23", cy: "78.47", r: "1.68" })))));
SystemsModernizationIcon.defaultProps = {
    "data-testid": "systems-modernization-icon",
};

export { SystemsModernizationIcon };
