import React__default from 'react';
import styled from 'styled-components';

const AddSpecIcon = (props) => (React__default.createElement(SVG, { width: props.size || "18", height: props.size || "18", active: props.active, viewBox: "0 0 18 18", version: "1.1", className: props.className },
    React__default.createElement("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
        React__default.createElement("g", { transform: "translate(9.000000, 9.000000) scale(-1, -1) translate(-9.000000, -9.000000) translate(3.500000, 2.000000)" },
            React__default.createElement("polygon", { id: "Rectangle-path", strokeLinecap: "round", strokeLinejoin: "round", points: "8 11 9.60910912 11 11 11 11 14 8 14" }),
            React__default.createElement("path", { d: "M8,12.5 L5.14285714,12.5 C4.51167457,12.5 4,11.9883254 4,11.3571429 L4,8.5", id: "Shape", strokeLinecap: "round", strokeLinejoin: "round" }),
            React__default.createElement("polygon", { id: "Rectangle-path", strokeLinecap: "round", strokeLinejoin: "round", points: "0 0.5 4.29095766 0.5 8 0.5 8 8.5 0 8.5" }),
            React__default.createElement("path", { d: "M1.1875,5 C1.08394661,5 1,4.77614237 1,4.5 C1,4.22385763 1.08394661,4 1.1875,4 L6.8125,4 C6.91605339,4 7,4.22385763 7,4.5 C7,4.77614237 6.91605339,5 6.8125,5 L1.1875,5 Z", id: "Shape", fillRule: "nonzero" }),
            React__default.createElement("path", { d: "M1.1875,5 C1.08394661,5 1,4.77614237 1,4.5 C1,4.22385763 1.08394661,4 1.1875,4 L6.8125,4 C6.91605339,4 7,4.22385763 7,4.5 C7,4.77614237 6.91605339,5 6.8125,5 L1.1875,5 Z", id: "Shape", fillRule: "nonzero", transform: "translate(4.000000, 4.500000) rotate(90.000000) translate(-4.000000, -4.500000) " })))));
AddSpecIcon.defaultProps = {
    active: true,
    size: 18,
    className: "",
};
const SVG = styled.svg `
  polygon {
    stroke: ${(props) => (props.active ? "#56BF98" : "#EDEFEE")};
  }
  path {
    fill: ${(props) => (props.active ? "#56BF98" : "#EDEFEE")};
  }
`;

export default AddSpecIcon;
