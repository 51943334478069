import React__default from 'react';

const ApolloGraphqlIcon = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "Apollo GraphQL Consulting icon"),
    React__default.createElement("defs", null,
        React__default.createElement("style", null, `
        .cls04-1 {
        fill: none;
        stroke: #323433;
        stroke-width: 2px;
      }

        .cls04-2 {
        fill: #bae1d3;
      }
      `)),
    React__default.createElement("g", { id: "Layer_1", "data-name": "Layer 1" },
        React__default.createElement("g", null,
            React__default.createElement("g", null,
                React__default.createElement("path", { className: "cls04-1", d: "M35,19.3a34.17,34.17,0,0,1,30.13,0" }),
                React__default.createElement("path", { className: "cls04-1", d: "M80.7,34.86A34.15,34.15,0,0,1,80.7,65" })),
            React__default.createElement("line", { className: "cls04-1", x1: "57.96", y1: "42.04", x2: "65.96", y2: "34.04" }),
            React__default.createElement("line", { className: "cls04-1", x1: "34.2", y1: "65.8", x2: "42.04", y2: "57.96" }),
            React__default.createElement("path", { className: "cls04-2", d: "M40.13,74a14.17,14.17,0,0,1-28.34,0" }),
            React__default.createElement("path", { className: "cls04-2", d: "M88.21,25.75a14,14,0,0,0-14-14v14" }),
            React__default.createElement("circle", { className: "cls04-1", cx: "74.2", cy: "74.04", r: "11.15" }),
            React__default.createElement("path", { className: "cls04-1", d: "M35.2,80.28A11.15,11.15,0,1,1,37.11,74a11.13,11.13,0,0,1-1.91,6.24" }),
            React__default.createElement("circle", { className: "cls04-1", cx: "50.08", cy: "49.92", r: "11.15" }),
            React__default.createElement("circle", { className: "cls04-1", cx: "74.2", cy: "25.8", r: "11.15" }),
            React__default.createElement("circle", { className: "cls04-1", cx: "25.96", cy: "25.8", r: "11.15" })))));
ApolloGraphqlIcon.defaultProps = {
    "data-testid": "apollo-graphql-icon",
};

export { ApolloGraphqlIcon };
