import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { e as AcceptanceGreenUltimatelyLight } from '../../../colors-c52fac99.js';
import { Confetti2x4 } from '../Icons/Confetti2x4.js';
import { Confetti6x8Big } from '../Icons/Confetti6x8Big.js';

const ConfettiSectionWrapper = (props) => {
    return (React__default.createElement(Wrapper, { style: props.style },
        React__default.createElement(Confetti6x8Big, { className: "desktop-only", style: {
                position: "absolute",
                left: 10,
                top: 10,
            } }),
        React__default.createElement(Confetti2x4, { className: "non-desktop-only", style: {
                transform: "scaleX(-1)",
                position: "absolute",
                left: 10,
                top: 10,
            } }),
        React__default.createElement(ContentWrapper, null, props.children),
        React__default.createElement(Confetti6x8Big, { className: "desktop-only", style: {
                transform: "scaleX(-1)",
                position: "absolute",
                right: 10,
                bottom: 10,
            } }),
        React__default.createElement(Confetti2x4, { className: "non-desktop-only", style: {
                position: "absolute",
                right: 10,
                bottom: 10,
            } })));
};
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div `
  position: relative;
  padding: ${3 * spacing.mobile.padding.default}px
    ${spacing.mobile.padding.default}px;
  background-color: ${AcceptanceGreenUltimatelyLight};
  display: flex;
  flex-direction: column;

  .non-desktop-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }

  @media ${device.wide} {
    .non-desktop-only {
      display: none;
    }
    .desktop-only {
      display: block;
    }

    margin-left: 60px;
    flex-direction: row;
    justify-content: center;
    padding: 80px 180px 130px;
  }
`;

export { ConfettiSectionWrapper };
