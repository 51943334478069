import React__default from 'react';

const TestersIcon = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "100", height: "100", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "Magnifying glass icon"),
    React__default.createElement("g", { fill: "none", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 0h100v100H0z" }),
        React__default.createElement("path", { d: "M35.05 65L17.214 82.907A4.157 4.157 0 0 0 20.143 90c1.058 0 2.122-.41 2.928-1.22L41 70.784A24.327 24.327 0 0 1 35.05 65" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M35.17 63.463L16.505 82.201A5.157 5.157 0 0 0 20.143 91a5.131 5.131 0 0 0 3.637-1.514l18.778-18.849-.994-.679a23.326 23.326 0 0 1-5.705-5.546l-.69-.949zm4.293 7.447l-17.1 17.164c-.598.6-1.397.926-2.22.926a3.158 3.158 0 0 1-2.221-5.386L34.95 66.517a25.333 25.333 0 0 0 4.513 4.393z" }),
        React__default.createElement("path", { d: "M56 58c-2.762 0-5-2.26-5-5.05V11h10v41.95c0 2.79-2.238 5.05-5 5.05" }),
        React__default.createElement("path", { fill: "#BAE1D3", d: "M53.034 55.988V39.473c.118-.014-.117-8.323 0-8.335 2.426-.257 5.707-.342 7.116 1.482.396.513.617 1.129 1.043 1.622.687.795 1.699 1.098 2.807 1.137v20.61C64 58.748 61.538 61 58.517 61c-3.02 0-5.483-2.25-5.483-5.012" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M56 59c-3.316 0-6-2.71-6-6.05V10h12v42.95c0 3.34-2.684 6.05-6 6.05zm-4-47v40.95c0 2.24 1.793 4.05 4 4.05s4-1.81 4-4.05V12h-8z" }),
        React__default.createElement("path", { fill: "#BAE1D3", d: "M84 55.882v-16.44c-.107-.014-.215-.066-.32-.078-2.214-.264-3.89-1.008-5.09.914-.654 1.046-.54 1.686-1.59 2.53-.797.638-2.027.891-3 .27v12.804C74 58.702 76.245 61 79 61s5-2.299 5-5.118" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M77 59c-3.316 0-6-2.71-6-6.05V10h12v42.95c0 3.34-2.684 6.05-6 6.05zm-4-6.05c0 2.24 1.793 4.05 4 4.05s4-1.81 4-4.05V12h-8v40.95zM49 11.5h14v-2H49z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M70 11.5h14v-2H70zM30 50.5C30 36.97 40.969 26 54.5 26 68.03 26 79 36.97 79 50.5 79 64.031 68.03 75 54.5 75 40.969 75 30 64.032 30 50.5zm2 0C32 62.927 42.073 73 54.5 73 66.926 73 77 62.927 77 50.5 77 38.074 66.926 28 54.5 28S32 38.074 32 50.5z" }))));
TestersIcon.defaultProps = {
    "data-testid": "icon-benefits-small",
};

export { TestersIcon };
