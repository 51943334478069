import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { CenteredContentWrapper } from '../layout/CenteredContentWrapper.js';
import { ButtonPrimaryLink } from '../ButtonPrimary/ButtonPrimary.js';

const ToutSmallFullwidth = ({ buttonText, buttonUrl, children, onClick, }) => (React__default.createElement(ContentWrapper, null,
    children,
    React__default.createElement(Button, { href: buttonUrl, as: onClick ? undefined : "a", onClick: onClick }, buttonText)));
const ContentWrapper = styled(CenteredContentWrapper) `
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0;
  max-width: 100vw;

  padding: 32px 20px;
  background-color: #f4f7f6;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > *:nth-child(1) {
    text-align: center;

    @media ${device.tablet} {
      text-align: left;
      margin-right: 40px;
    }
  }

  @media ${device.tablet} {
    padding: 40px;
    flex-direction: row;
  }

  @media ${device.desktop} {
    max-width: ${1170 - 2 * 40}px;
    flex-direction: row;
  }
`;
const Button = styled(ButtonPrimaryLink) `
  @media ${device.mobile} {
    margin-top: 32px;
  }

  @media ${device.tablet} {
    margin-top: 0px;
  }
`;

export { ToutSmallFullwidth };
