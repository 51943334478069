import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { Subheading, SectionHeaderSmall, TextBody } from '../typography.js';
import { Confetti4Rows } from '../Icons/Confetti4Rows.js';

const Bio = (props) => (React__default.createElement(Wrapper, { "data-testid": "profile" },
    React__default.createElement(ImageWrapper, null,
        props.elementWithBackgroundImage,
        React__default.createElement(Confetti, { flipConfetti: props.flipConfetti })),
    React__default.createElement(ContentWrapper, null,
        React__default.createElement(Overlay, null,
            React__default.createElement(Subheading, null, props.position),
            React__default.createElement(SectionHeaderSmall, { style: { marginTop: 0 } }, props.name)),
        React__default.createElement(Text, { "data-testid": props.name.replace(" ", "_") + "_description" }, props.description))));
const Wrapper = styled.div `
  position: relative;
  margin-bottom: -65px;
`;
const Confetti = styled(Confetti4Rows) `
  @media ${device.mobile} {
    display: none;
  }
  @media ${device.desktop} {
    display: block;
  }
  position: absolute;
  bottom: 10px;
  right: 5px;

  ${(props) => props.flipConfetti
    ? `
            bottom: 85px;
            left: 15px; 
            transform: scaleX(-1)
        `
    : ""}
`;
const ImageWrapper = styled.div `
  position: relative;
  overflow: hidden;

  > *:first-child {
    background-color: #f4f7f6;
    width: 100%;
    padding-top: 90%;
  }

  @media ${device.mobile} {
    max-height: 305px;
  }
`;
const Overlay = styled.div `
  padding-top: ${spacing.mobile.padding.default}px;
  display: relative;
  width: calc(100% - 50px);
  background-color: white;
`;
const ContentWrapper = styled.div `
  white-space: pre-line;
  position: relative;
  top: -75px;
  * {
    text-align: left;
  }
  margin-bottom: -65px;
`;
const Text = styled(TextBody) `
  text-align: left;
  line-height: 1.56;
`;

export { Bio };
