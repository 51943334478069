import React__default from 'react';

const BenefitsSmallIcon = (props) => (React__default.createElement("svg", Object.assign({ width: "64", height: "64", viewBox: "0 0 64 64" }, props),
    React__default.createElement("title", null, "Flipchart icon"),
    React__default.createElement("g", { fill: "none", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 0h64v64H0z" }),
        React__default.createElement("path", { fill: "#BAE1D3", d: "M4 53h22V11H4z" }),
        React__default.createElement("g", { fill: "#3C3F3E", fillRule: "nonzero" },
            React__default.createElement("path", { d: "M58.884 12.97v7.018h2V10.97h-9.361v2z" }),
            React__default.createElement("path", { d: "M39.634 30.827l-6.206-6.21L14.575 43.49l1.415 1.413 17.439-17.457 6.205 6.211 20.957-20.98-1.415-1.413z" })),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M55 48.5H9v-32h31.866v-2H7v36h50V30.491h-2z" }))));
BenefitsSmallIcon.defaultProps = {
    "data-testid": "icon-benefits-small",
};

export { BenefitsSmallIcon };
