import React__default from 'react';

const StarIcon = () => (React__default.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 50 50" },
    React__default.createElement("g", { id: "Layer_1", "data-name": "Layer 1" },
        React__default.createElement("rect", { fill: "#57bf98", width: "50", height: "50" }),
        React__default.createElement("path", { fill: "#fff", d: "M22.91,8.25a2.33,2.33,0,0,1,4.18,0l4.35,8.81-5.61-.82L25,14.56l-3.35,6.76-5.58.81Z" }),
        React__default.createElement("path", { fill: "#57bf98", d: "M25,7.78a1.5,1.5,0,0,1,1.34.84L30,16l-3.61-.53L25,12.68l-3.9,7.88-3.58.52L23.66,8.62A1.5,1.5,0,0,1,25,7.78m0-1.67a3.18,3.18,0,0,0-2.84,1.78L16,20.34l-1.41,2.84,3.14-.45,3.58-.52.87-.13.38-.78L25,16.44l.27.56.87.13,3.61.53,3.14.46-1.41-2.85L27.83,7.88A3.17,3.17,0,0,0,25,6.11Z" }),
        React__default.createElement("path", { fill: "#fff", d: "M34.45,23.73l1.36-1.32-7.46-1.08-2.52-5.08L31,17l10.16,1.48a2.33,2.33,0,0,1,1.29,4l-7,6.84Z" }),
        React__default.createElement("path", { fill: "#57bf98", d: "M27.28,17.3l3.6.52L41,19.3a1.5,1.5,0,0,1,.83,2.56L36,27.6,35.35,24l2.25-2.19-8.7-1.26L27.28,17.3m-2.9-2.1L25.79,18l1.62,3.27.39.78.86.13L34,23l-.45.45.15.86.62,3.58L34.87,31l2.26-2.21L43,23.05a3.17,3.17,0,0,0,.81-3.23,3.22,3.22,0,0,0-2.56-2.17L31.12,16.17l-3.6-.52-3.14-.45Z" }),
        React__default.createElement("path", { fill: "#fff", d: "M35.66,40.6a1.49,1.49,0,0,1-.88.28,1.45,1.45,0,0,1-.7-.17l-7.3-3.83L30,35.18l2.77,1.46L31.3,28l2.6-2.54,2.36,13.7A1.53,1.53,0,0,1,35.66,40.6Z" }),
        React__default.createElement("path", { fill: "#fff", d: "M28.22,34.24l-12.3,6.47a1.52,1.52,0,0,1-1.58-.11,1.51,1.51,0,0,1-.6-1.47L15.14,31l2.61,2.54-.54,3.1L25,32.55Z" }),
        React__default.createElement("path", { fill: "#fff", d: "M18.7,28l-.61,3.58-10-9.69a1.52,1.52,0,0,1-.38-1.54A1.5,1.5,0,0,1,9,19.3l8.17-1.19-1.61,3.26-3.12.46Z" }))));
StarIcon.defaultProps = {
    "data-testid": "icon-xspecs-small",
};

export { StarIcon };
