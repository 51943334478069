import React from "react";
import {
  ShapesIcon,
  ScreenIcon,
  FlipchartIcon,
  StarIcon,
  DigitalStrategyIcon,
  ApolloGraphqlIcon,
  BespokeMartechIcon,
  EndToEndIcon,
  HeadlessEcommerceIcon,
  SolutionIdeationIcon,
  StaffAugmentationIcon,
  SystemsModernizationIcon,
} from "@xolvio/xolvio-ui";
import { navigate } from "@reach/router";

export const defaultHeroProps = {
  title: "Solve for the future, today.",
  highlightedTitleFragments: "future",
  backgroundImageUrl: "/assets/images/man_hand_up.jpg",
  height: "750px",
  contentBlock: (
    <>
      We help enterprises rapidly build complex, high-quality software that scales infinitely.
    </>
  ),
  actionButtons: null,
};

const servicesDescriptions = [
  {
    heading: "Custom Software Development",
    description:
      "Deliver quality at speed. A reliable partner that delivers with a bullet-proof process.",
    icon: <ScreenIcon />,
    onClick: () => navigate("/services/custom-software-development"),
    url: "/services/custom-software-development",
    sectionId: "custom-software-development",
  },
  {
    heading: "Apollo GraphQL Consulting",
    description:
      "On-demand Apollo GraphQL expertise. Get absolute confidence in your graph with speed-dial access to our team.",
    icon: <ShapesIcon />,
    onClick: () => navigate("/services/apollo-graphql-consulting"),
    url: "/services/apollo-graphql-consulting",
    sectionId: "apollo-graphql-consulting",
  },
  {
    heading: "Continuous Delivery Coaching",
    description:
      "Unlock your ability to scale. Modernize your product development life cycle through our QualityFaster enablement.",
    icon: <FlipchartIcon />,
    onClick: () => navigate("/services/continuous-delivery-coaching"),
    url: "/services/continuous-delivery-coaching",
    sectionId: "continuous-delivery-coaching",
  },
];

export const defaultServicesProps = {
  title: "Let us up your game",
  subheading: "SERVICES",
  sections: servicesDescriptions,
};

export const defaultHeroInlineProps = {
  heading: "Here’s how we helped them solve for the future:",
  subheading: "OUR WORK",
  height: "450px",
  actionButtons: null,
  style: { position: "relative" },
  imageOverlayTextComponent: (
    <>
      <h1>We know you need absolute confidence in your technology.</h1>
      <h2>And we know how to get you there.</h2>
    </>
  ),
  content: "Services to help you leverage technology your way",
  contentHighlights: "your way",
};

export const tout1 = {
  title: "Audi of America",
  icon: <StarIcon />,
  subheading: "e-tron reservations system",
  content:
    "Highly complex, bespoke e-reservation platform capable of processing thousands of reservations per minute. The e-tron reservations system was developed in record time, yet it performed perfectly and at scale from the get-go.",
  url: "/case-studies/audi-case-study/",
  buttonText: "See case study",
};

export const tout2 = {
  title: "Volkswagen of America",
  icon: <StarIcon />,
  subheading: "Car-Net infrastructure",
  content:
    "Advanced cloud infrastructure to host the Car-Net platform, a connected car services suite. Our work empowered Volkswagen and their development partner to confidently deliver at lightning speed, while maintaining high quality.",
  url: "/case-studies/volkswagen-case-study/",
  buttonText: "See case study",
};

export const tout3 = {
  title: "StudyKIK",
  icon: <StarIcon />,
  subheading: "System modernization & API development",
  content:
    "Complex monolithic system refactor to event-driven, distributed architecture in just three months. In parallel to the modernization, we designed & implemented an Apollo supergraph, enabling agile CX innovation.",
  url: "/case-studies/studykik-case-study/",
  buttonText: "See case study",
};

export const defaultImageToutProps = {
  title: "#QualityFaster Guide",
  image: "/assets/images/qualit-faster-book-cover.png",
  content:
    "Enroll in our ultimate learning guide. A complete full-team, full-cycle and full-stack guide to help you and your team increase the speed at which you deliver software while simultaneously increasing quality.",
  url: "https://www.qualityfaster.com/",
  buttonText: "Learn more",
};

export const defaultTestimonialProps = {
  imageUrl: "/assets/images/albert-einstein.png",
  content:
    "Xolvio laid the foundation for all of our testing and it has allowed us to ship tremendously faster. Xolvio laid the foundation for all of our testing and it has allowed us to ship tremendously faster.",
  signature: "Jon Perl. CTO. Dispatch",
  elementWithBackgroundImage: (
    <div
      style={{
        backgroundImage: "url(/assets/images/logos/audi.svg)",
      }}
    />
  ),
};

export const contactUsFooterProps = {
  heading: "Pre-engagement consultation workshop",
  subheading: "HOW TO GET STARTED",
  content:
    "Witness the impact of visual requirements facilitation and our problem solving prowess in a 90 min. consulting session.",
  buttonText: "Request consultation",
  buttonUrl: "/contact-us",
};

export const defaultFooterProps = {
  contactActionUrl:
    "https://simian.us9.list-manage.com/subscribe/post?u=658edfe7fca9a502c46dd331e&amp;id=7f9763afd9",
  linkGroups: [
    {
      name: "DIGITAL EXPERIENCE INTEGRATION",
      links: [
        {
          name: "Apollo GraphQL Consulting",
          url: "/services/apollo-graphql-professional-services/",
        },
        {
          name: "Experience Graph Managed Services",
          url: "/services/experience-graph-managed-services/",
        },
        {
          name: "GraphQL Security Review & Remediation",
          url: "/services/graphql-security-review-remediation",
        },
      ],
    },
    {
      name: "ACCELERATED SOFTWARE TRANSFORMATION",
      links: [
        {
          name: "SDLC & Architecture Consulting",
          url: "/services/sdlc-architecture-consulting",
        },
        {
          name: "Rapid Backend Development",
          url: "/services/rapid-backend-development",
        },
        {
          name: "Legacy Systems Modernization",
          url: "/services/systems-modernization",
        },
      ],
    },
    {
      name: "CASE STUDIES",
      links: [
        {
          name: "StudyKIK",
          url: "/case-studies/studykik-case-study/",
        },
        {
          name: "Audi of America",
          url: "/case-studies/audi-case-study/",
        },
        {
          name: "Volkswagen of America",
          url: "/case-studies/volkswagen-case-study/",
        },
      ],
    },
    {
      name: "LEARN MORE",
      links: [
        {
          name: "How We Work",
          url: "/how-we-work/",
        },
        {
          name: "Who We Are",
          url: "/who-we-are/",
        },
        {
          name: "Careers",
          url: "/careers/",
        },
      ],
    },
  ],
  legaleseLinks: [
    {
      name: "Privacy policy",
      url: "/privacy-policy",
    },
  ],
  socialLinks: {
    twitter: "https://twitter.com/xolvio",
    linkedin: "https://www.linkedin.com/company/xolvio/",
  },
};

export const defaultInlineHeroContent = {
  heading:
    "As a company, you've reached a point where building fast at scale is a major challenge.",
  headingHighlights: ["at scale"],
  contentBold:
    "The current economy calls for unprecedented performance when it comes to software delivery. The digital transformation race is on, but is your road towards digital maturity clear?",
  contentRegular:
    "While we live in the age of distributed systems, people aren’t trained to think in a distributed fashion, severing their ability to build systems that scale to meet modern challenges. Though easier said than done, it’s high time to break up your monoliths. The good news is we know how to incrementally scale both your architecture and your delivery, allowing you to tame the complexity you’re faced with.",
};

export const defaultTwoColumnsWithImageContent = {
  heading: "Meet your new trusted partner, Xolvio",
  headingHighlights: "trusted",
  content:
    "Through our extensive experience in solving complex, high-risk issues, we worked out a unique, bulletproof method to deliver higher quality software, faster. Our holistic approach to digital architecture and software delivery means you will not only get a strategic plan but also bespoke solutions for your particular challenges. In effect, you gain digital mastery over your business processes that allows you to grow, surely and steadily.",
};

export const defaultWhatWeDoContent = {
  subheading: "WHAT WE DO",
  heading: "Services to help you leverage technology your way",
  headingHighlights: "your way",
  content:
    "By marrying software architecture & design expertise with extensive delivery experience, we offer you a full suite of services to take both your tech stack and your delivery capabilities to the next level. We live and breathe high quality solutions and want to empower your organization to make the most out of technology. Let us get you on the road to digital maturity.",
};

export const defaultTilesProps = {
  columnTitle1: "Software Architecture & Design",
  columnTitle2: "Custom Software Development",
  tile1: {
    icon: <DigitalStrategyIcon />,
    title: "Technical Strategy & Architecture",
    onClick: () => navigate("/services/technical-strategy-and-architecture/"),
    isSelected: false,
  },
  tile2: {
    icon: <SolutionIdeationIcon />,
    title: "Solution Ideation & Validation",
    onClick: () => navigate("/services/solution-ideation-and-validation/"),
    isSelected: false,
  },
  tile3: {
    icon: <SystemsModernizationIcon />,
    title: "Systems Modernization",
    onClick: () => navigate("/services/systems-modernization/"),
    isSelected: false,
  },
  tile4: {
    icon: <ApolloGraphqlIcon />,
    title: "Apollo GraphQL Consulting",
    onClick: () => navigate("/services/apollo-graphql-consulting/"),
    isSelected: false,
  },
  tile5: {
    icon: <EndToEndIcon />,
    title: "End-to-end Delivery",
    onClick: () => {
      //TODO navigation
    },
    isSelected: false,
  },
  tile6: {
    icon: <StaffAugmentationIcon />,
    title: "Staff Augmentation",
    onClick: () => {
      //TODO navigation
    },
    isSelected: false,
  },
  tile7: {
    icon: <BespokeMartechIcon />,
    title: "Bespoke Martech Solutions",
    onClick: () => navigate("/services/bespoke-martech-solutions/"),
    isSelected: false,
  },
  tile8: {
    icon: <HeadlessEcommerceIcon />,
    title: "Headless E-commerce Solutions",
    onClick: () => navigate("/services/headless-ecommerce-solutions"),
    isSelected: false,
  },
};

export const quote = {
  body: "Xolvio offers a level of experience and cross-functional value that we have never encountered anywhere else, nor have we been able to find from any single organization.",
  signature: "Jeff Titus, Audi",
};
