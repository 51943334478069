import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';

const HeroBackgroundWrapper = styled.div `
  > * {
    align-items: center;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  width: calc(100vw - 60px);
  margin-left: calc((100% - 1400px) / 2);

  height: ${(props) => props.height};
  
  @media ${device.mobile} {
    margin-left: 0;
    width: calc(100vw - ${spacing.mobile.padding.default}px);
    max-height: 250px;
  }

  @media ${device.tabletVertical} {
    max-height: 450px;
  }

  @media ${device.tabletVertical} {
    max-height: 650px;
  }

  @media ${device.tablet} {
    width: ${(props) => props.inline
    ? `calc(100vw - ${spacing.mobile.padding.default}px)`
    : "100vw"};
    max-width: 100vw;
    margin-right: ${(props) => props.inline ? `${spacing.mobile.padding.default}px` : "initial"};
  }

  @media ${device.desktop} {
    max-height: initial;
  }

  @media ${device.wide} {
    margin-right: ${(props) => props.inline
    ? `calc((100vw - ${spacing.desktop.maxContentWidth}px) / 2)`
    : `calc((100vw - ${spacing.desktop.maxContentWidth}px) / 2 - 190px)`};

    width: calc(
      ${spacing.desktop.maxContentWidth}px +
        (100vw - ${spacing.desktop.maxContentWidth}px) / 2 ${(props) => props.inline ? "" : " + 190px"});
    );
    max-width: 1700px;
  } 
  }
`;
HeroBackgroundWrapper.defaultProps = {
    height: "100px",
};

export { HeroBackgroundWrapper };
