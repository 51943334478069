import React__default, { Component } from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { T as TextBlack } from '../../../colors-c52fac99.js';
import { TextBody, Subheading } from '../typography.js';
import { Confetti9Rows } from '../Icons/Confetti9Rows.js';
import { ArrowRightIcon } from '../Icons/ArrowRight.js';
import { ArrowLeftIcon } from '../Icons/ArrowLeft.js';
import { SplitBackground } from '../layout/SplitBackground.js';

const defaultProps = {
    subheading: "TESTIMONIAL",
    heading: "We deliver",
    highlightedTitleFragments: "deliver",
};
class TestimonialsNoImage extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            displayedIndex: 0,
        };
        this.showNext = () => {
            const { displayedIndex } = this.state;
            const nextIndex = displayedIndex >= this.props.testimonials.length - 1
                ? 0
                : displayedIndex + 1;
            this.setState({ displayedIndex: nextIndex });
        };
        this.showPrevious = () => {
            const { displayedIndex } = this.state;
            const nextIndex = displayedIndex <= 0
                ? this.props.testimonials.length - 1
                : displayedIndex - 1;
            this.setState({ displayedIndex: nextIndex });
        };
    }
    render() {
        return (React__default.createElement(SplitBackground, null,
            React__default.createElement(DesktopAbsolute, { style: {
                    position: "absolute",
                    right: 0,
                    height: "100%",
                } },
                React__default.createElement(Confetti9Rows, { className: "tablet-up", "data-testid": "confetti-image-top", style: {
                        position: "absolute",
                        right: 0,
                        transform: "translateX(40px)",
                    } })),
            React__default.createElement(Desktop, null,
                React__default.createElement(ArrowLeftIcon, { onClick: this.showPrevious })),
            React__default.createElement(ContentWrapper, null,
                React__default.createElement(SubheadingStyled, null, this.props.subheading),
                React__default.createElement(TextBody, { style: {
                        margin: "0 0 10px",
                        fontSize: 24,
                        letterSpacing: "-0.1px",
                        color: TextBlack,
                    } }, this.props.testimonials[this.state.displayedIndex].content),
                React__default.createElement(LogoWrapper, null, this.props.testimonials[this.state.displayedIndex]
                    .elementWithBackgroundImage)),
            React__default.createElement(Desktop, null,
                React__default.createElement(ArrowRightIcon, { onClick: this.showNext })),
            React__default.createElement(Mobile, null,
                React__default.createElement(ArrowsWrapper, null,
                    React__default.createElement(ArrowLeftIcon, { onClick: this.showPrevious }),
                    React__default.createElement(ArrowRightIcon, { onClick: this.showNext }))),
            React__default.createElement(DesktopAbsolute, { style: {
                    left: 0,
                    bottom: 0,
                    height: "100%",
                } },
                React__default.createElement(Confetti9Rows, { className: "tablet-up", style: {
                        position: "absolute",
                        bottom: -0,
                        transform: "translateX( -40px)",
                    }, "data-testid": "confetti-image-bottom" }))));
    }
}
TestimonialsNoImage.defaultProps = defaultProps;
const ArrowsWrapper = styled.div `
  display: flex;
  justify-content: center;

  > svg {
    margin: 10px;
    cursor: pointer;
    @media ${device.tablet} {
      margin: 0 20px 0;
    }
  }

  @media ${device.tablet} {
    justify-content: flex-start;
  }
`;
const SubheadingStyled = styled(Subheading) `
  text-align: center !important;
  max-width: 555px;

  margin-bottom: ${spacing.mobile.padding.default}px;
`;
const LogoWrapper = styled.div `
  margin-top: 30px;

  > * {
    height: 50px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
`;
const Mobile = styled.div `
  @media ${device.desktop} {
    visibility: hidden;
    display: none;
  }
`;
const Desktop = styled.div `
  @media ${device.mobile} {
    display: none;
  }
`;
const DesktopAbsolute = styled(Desktop) `
  position: absolute;
`;
const ContentWrapper = styled.div `
  > * {
    text-align: center;
    width: 100%;
    max-width: 555px;
  }

  @media ${device.desktop} {
    margin: auto 75px;
  }
`;

export { LogoWrapper, TestimonialsNoImage };
