import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import TwitterIcon from '../Icons/TwitterIcon.js';
import LinkedInIcon from '../Icons/LinkedInIcon.js';
import YoutubeIcon from '../Icons/YoutubeIcon.js';

const SocialIcons = ({ youtube = "https://www.youtube.com/c/XolvIo/videos", twitter = "https://twitter.com/xolvio", linkedin = "https://www.linkedin.com/company/xolvio/", }) => (React__default.createElement(SocialIconsWrapper, null,
    twitter && (React__default.createElement("a", { href: twitter, target: "_blank", "aria-label": "Xolvio twitter profile" },
        React__default.createElement(TwitterIcon, null))),
    linkedin && (React__default.createElement("a", { href: linkedin, target: "_blank", "aria-label": "Xolvio linkedin profile" },
        React__default.createElement(LinkedInIcon, null))),
    youtube && (React__default.createElement("a", { href: youtube, target: "_blank", "aria-label": "Xolvio youtube profile" },
        React__default.createElement(YoutubeIcon, null)))));
const SocialIconsWrapper = styled.div `
  display: flex;
  align-items: center;

  > :not(:last-child) {
    margin-right: ${spacing.mobile.padding.default}px;
  }
`;

export { SocialIcons, SocialIconsWrapper };
