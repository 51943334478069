import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { T as TextBlack } from '../../../colors-c52fac99.js';
import { TextBody } from '../typography.js';
import { QuoteIcon } from '../Icons/QuoteIcon.js';

const Quote = (props) => (React__default.createElement(Wrapper, null,
    React__default.createElement(QuoteIcon, null),
    React__default.createElement(ContentWrapper, null,
        React__default.createElement(QuoteBody, null, props.body),
        React__default.createElement(Signature, null, props.signature))));
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;

  @media ${device.tabletVertical} {
    flex-direction: row;
  }
`;
const ContentWrapper = styled.div `
  @media ${device.tabletVertical} {
    margin-left: 34px;
  }
`;
const QuoteBody = styled(TextBody) `
  text-align: left;
  color: ${TextBlack};
  font-size: 27px;
  letter-spacing: -0.25px;
  margin-top: ${(4 / 3) * spacing.mobile.padding.default}px;
  margin-bottom: ${spacing.mobile.padding.default}px;
  max-width: 550px;
  line-height: normal;

  @media ${device.tabletVertical} {
    margin-top: 0;
    font-size: 36px;
    letter-spacing: -0.34px;
  }
`;
const Signature = styled(TextBody) `
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.2px;
  color: ${TextBlack};
  margin-bottom: 0;
`;

export { Quote };
