import React__default from 'react';

const BespokeMartechIcon = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "Bespoke Martech Solutions icon"),
    React__default.createElement("defs", null,
        React__default.createElement("style", null, `
      .cls07-1, .cls07-3 {
        fill: #bae1d3;
      }

      .cls07-2, .cls07-4, .cls07-5 {
        fill: none;
      }

      .cls07-2, .cls07-3, .cls07-4, .cls07-5 {
        stroke: #323433;
        stroke-width: 2px;
      }

      .cls07-4, .cls07-5 {
        stroke-linejoin: round;
      }

      .cls07-5 {
        stroke-linecap: square;
      }
      `)),
    React__default.createElement("g", { id: "ludziki" },
        React__default.createElement("g", null,
            React__default.createElement("path", { className: "cls07-1", d: "M93.13,39.16H77.82A21.94,21.94,0,0,0,55.91,17.25V1.94A37.26,37.26,0,0,1,93.13,39.16Z" }),
            React__default.createElement("circle", { className: "cls07-2", cx: "55.92", cy: "39.16", r: "26.97" }),
            React__default.createElement("circle", { className: "cls07-2", cx: "20.53", cy: "74.54", r: "9.04" }),
            React__default.createElement("line", { className: "cls07-2", x1: "30.4", y1: "30.42", x2: "12.18", y2: "71.07" }),
            React__default.createElement("circle", { className: "cls07-2", cx: "20.53", cy: "74.54", r: "9.04" }),
            React__default.createElement("line", { className: "cls07-2", x1: "30.4", y1: "30.42", x2: "12.18", y2: "71.07" }),
            React__default.createElement("line", { className: "cls07-2", x1: "68.35", y1: "63.1", x2: "26.84", y2: "81.01" }),
            React__default.createElement("path", { className: "cls07-3", d: "M20.53,74.54V88.21A13.67,13.67,0,0,1,6.87,74.54Z" }),
            React__default.createElement("path", { className: "cls07-2", d: "M47.3,33.16v9.47H43.44a4.73,4.73,0,0,1-4.73-4.73h0a4.73,4.73,0,0,1,4.73-4.74" }),
            React__default.createElement("polygon", { className: "cls07-4", points: "47.3 33.16 67.1 27.06 67.1 48.73 47.3 42.63 47.3 33.16" }),
            React__default.createElement("path", { className: "cls07-2", d: "M43.92,42.63v5.8a1.69,1.69,0,0,0,1.69,1.69h0a1.69,1.69,0,0,0,1.69-1.69V46.06" }),
            React__default.createElement("path", { className: "cls07-5", d: "M67.17,34.33a3.57,3.57,0,1,1,0,7.13" }),
            React__default.createElement("line", { className: "cls07-4", x1: "61.93", y1: "34.87", x2: "61.93", y2: "47.04" }),
            React__default.createElement("line", { className: "cls07-4", x1: "61.93", y1: "28.24", x2: "61.93", y2: "32.25" })))));
BespokeMartechIcon.defaultProps = {
    "data-testid": "bespoke-martech-icon",
};

export { BespokeMartechIcon };
