import styled from 'styled-components';
import { A as AcceptanceGreen } from './colors-c52fac99.js';
import { ButtonPrimary } from './src/components/ButtonPrimary/ButtonPrimary.js';

const ButtonInline = styled(ButtonPrimary) `
  font-size: 18px;
  color: ${AcceptanceGreen};
  background-color: transparent;
`;
const ButtonInlineLink = styled(ButtonInline) `
  cursor: pointer;
  display: inline-block;
  padding: 15px 0;
  text-decoration: none;
  letter-spacing: 0;
  line-height: 1.2;
`;

export { ButtonInlineLink as B };
