import React__default from 'react';

const DigitalStrategyAndArchitectureShape = () => (React__default.createElement("svg", { width: "100", height: "100", viewBox: "0 0 100 100" },
    React__default.createElement("title", null, "Digital Strategy And Architecture icon"),
    React__default.createElement("g", { id: "Layer_1", "data-name": "Layer 1" },
        React__default.createElement("g", null,
            React__default.createElement("path", { fill: "#bae1d3", d: "M86.6,36.6a13.4,13.4,0,1,1,0,26.8" }),
            React__default.createElement("rect", { fill: "#bae1d3", y: "37.38", width: "12.64", height: "25.23" }),
            React__default.createElement("rect", { fill: "none", stroke: "#323433", strokeWidth: "2px", x: "40.68", y: "11.79", width: "18.37", height: "18.37" }),
            React__default.createElement("rect", { fill: "none", stroke: "#323433", strokeWidth: "2px", x: "3.46", y: "40.82", width: "18.37", height: "18.37" }),
            React__default.createElement("rect", { fill: "none", stroke: "#323433", strokeWidth: "2px", x: "40.68", y: "40.82", width: "18.37", height: "18.37" }),
            React__default.createElement("rect", { fill: "none", stroke: "#323433", strokeWidth: "2px", x: "40.68", y: "69.84", width: "18.37", height: "18.37" }),
            React__default.createElement("circle", { fill: "none", stroke: "#323433", strokeWidth: "2px", cx: "86.6", cy: "50", r: "10.11" }),
            React__default.createElement("path", { fill: "none", stroke: "#323433", strokeWidth: "2px", d: "M40.68,21c-18.85,0,0,29-18.85,29H40.68" }),
            React__default.createElement("path", { fill: "none", stroke: "#323433", strokeWidth: "2px", d: "M21.83,50c18.85,0,0,29,18.85,29" }),
            React__default.createElement("line", { fill: "none", stroke: "#323433", strokeWidth: "2px", x1: "59.05", y1: "50", x2: "77.42", y2: "50" })))));

export { DigitalStrategyAndArchitectureShape };
