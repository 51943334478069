import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { Subheading, SectionHeader, TextBody } from '../typography.js';
import { ButtonPrimaryLink } from '../ButtonPrimary/ButtonPrimary.js';

const TextToutFullwidth = ({ id, heading, subheading, content, buttonText, buttonUrl, style, }) => (React__default.createElement(Wrapper, { style: style, id: id },
    React__default.createElement(ContentWrapper, null,
        React__default.createElement(Subheading, null, subheading),
        React__default.createElement(SectionHeader, null, heading),
        React__default.createElement(TextBody, null, content),
        React__default.createElement(ButtonPrimaryLink, { href: buttonUrl, style: { marginTop: spacing.mobile.padding.default }, as: "a" }, buttonText))));
const Wrapper = styled.div `
  padding: 0 ${spacing.mobile.padding.default}px;
  background-color: #f4f7f6;
  display: flex;
`;
const ContentWrapper = styled.div `
  text-align: center;
  margin: 80px auto;

  > * {
    text-align: center;
    max-width: 750px;
  }

  @media ${device.tablet} {
    margin: 127px auto;
  }
`;

export { TextToutFullwidth };
