import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { A as AcceptanceGreen } from '../../../colors-c52fac99.js';
import { TextBody, Header } from '../typography.js';

function MenuItem(props) {
    return (React__default.createElement(MenuItemWrapper, { style: props.style, animate: props.animate, simpleMenu: props.simpleMenu },
        React__default.createElement(MenuItemContentWrapper, { onClick: () => {
                props.closeMenu && props.closeMenu();
                props.onClick && props.onClick();
            }, "data-testid": "menu-item", animate: props.animate, simpleMenu: props.simpleMenu, isDesktop: props.desktop },
            React__default.createElement(MenuLink, { underline: props.active, desktop: props.desktop, href: props.url, as: props.url ? "a" : "div" }, props.name),
            !props.hideSublinks && !props.simpleMenu && props.sublinks && (React__default.createElement(SublinksWrapper, { isDesktop: props.desktop, className: "sublinks" }, props.sublinks.map((sublink) => (React__default.createElement(Sublink, Object.assign({}, sublink, { isDesktop: props.desktop })))))))));
}
const Sublink = (props) => (React__default.createElement(SublinkWrapper, Object.assign({}, props, { href: props.url, as: "a" }),
    React__default.createElement(TextBody, null, props.name)));
// @ts-ignore
const MenuLink = styled(Header) `
  font-size: ${(props) => (props.desktop ? 16 : 24)}px;
  letter-spacing: -0.23px;
  text-align: left;
  padding: 5px 1px;
  margin-left: -1px;
  margin-right: -1px;
  ${(props) => (!props.desktop ? "padding: 0px 1px" : "")};
  text-decoration: none;

  background: none bottom/0% 2px no-repeat;
  ${(props) => props.underline
    ? `
      animation: 0.4s underline ease-out forwards;
  `
    : ""};

  @keyframes underline {
    0% {
      background: none bottom/0% 2px no-repeat;
    }
    100% {
      background: linear-gradient(${AcceptanceGreen}, ${AcceptanceGreen})
        bottom/100% 2px no-repeat;
    }
  }
`;
const MenuItemContentWrapper = styled.div `
  display: block;
  text-align: left;
  margin-right: ${(props) => (props.simpleMenu ? 0 : "25px")};
  cursor: pointer;
  color: black;
  ${(props) => props.animate
    ? `
        transition: color 0.2s ease-in-out;
        animation: 0.5s slideIn forwards;
    `
    : ""};
  margin-left: ${(props) => (props.simpleMenu ? 0 : "45px")};

  @media ${device.desktop} {
    margin-left: 0;
    margin-right: 45px;
  }

  ${({ isDesktop }) => isDesktop
    ? `
        :hover .sublinks {
          opacity: 1;
          visibility: visible;
          transition-delay: 0s;
          transition-duration: 0.1s;
        }
    `
    : `
      .sublinks {
          opacity: 1;
          visibility: visible
          }
      `};
`;
const MenuItemWrapper = styled.div `
  ${(props) => props.animate
    ? `
      opacity: 0;
      animation: 0.3s appear forwards;
    `
    : "opacity: 1"};

  ${(props) => (props.simpleMenu ? "margin-bottom: 20px" : "")};
`;
const SublinksWrapper = styled.div `
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.05s;
  transition-duration: 0.2s;

  ${({ isDesktop }) => isDesktop
    ? `
          margin-top: 10px;
          position: absolute;
          background-color: white;
          box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.09);
        `
    : ``};
`;
const SublinkWrapper = styled(MenuItemContentWrapper) `
  text-decoration: none;

  > * {
    width: fit-content;
    font-size: ${(props) => (props.isDesktop ? 16 : 24)}px;
    font-weight: bold;
    text-align: left;
    color: rgb(60, 63, 62);
    ${(props) => props.active
    ? `
      animation: 0.4s underline ease-out forwards;
  `
    : ""};
  }

  ${({ isDesktop }) => isDesktop
    ? `
          {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            margin: 0 !important;
            padding: 12px 20px 10px 18px;
            
            p {
              margin: 0;
            }
          }
        `
    : `
      
      margin-left: 20px;
          p {
              margin: 0;
           }
           
           `};
`;

export { MenuItem, MenuItemContentWrapper, MenuItemWrapper };
