import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Menu, FooterNew, device, defaultHeaderPropsNew } from "@xolvio/xolvio-ui";
import { defaultFooterProps } from "../pages-content/homepage";
import { Helmet } from "react-helmet";
import pageMetadata from "../pages-content/pageMetadata.json"
import "../reset.css";
import "../fonts.css";
import {useHubSpot} from './useHubSpot'

const defaultSeo = {
  SiteName: "Xolvio",
  Title: "Xolvio",
  Description: "Quality, faster. Consulting and software solutions that help you create more valuable products and deliver them at higher quality.",
  Image: "http://www.xolv.io/xolvio-logo.png",
}

const prepareMenuItems = (items = [], currentPath = "/") =>
  items.map(item => {
    // console.log(
    //   "_______ currentPath,  item.url, currentPath === item.url",
    //   currentPath,
    //   item.url,
    //   currentPath === item.url
    // );
    return {
      ...item,
      active: currentPath === item.url,
      sublinks: item.sublinks
        ? item.sublinks.map(sublink => {
            // console.log(
            //   "_______ currentPath,  sublink.url, currentPath === sublink.url",
            //   currentPath,
            //   sublink.url,
            //   currentPath === sublink.url
            // );
            return {
              ...sublink,
              active: currentPath === sublink.url
            };
          })
        : undefined
    };
  });

export const LayoutDefault = props => {
  const [currentPath, setCurrentPath] = useState("");
  const [metadata, setMetadata] = useState(defaultSeo)
  useHubSpot()

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCurrentPath(window.location.pathname);
      window.scrollTo(0, 0)
    }
  }, []);

  useEffect(() => {
    const data = pageMetadata.find(({ URL }) => URL === currentPath);
    if (data) {
      console.log('Founded custom metadata', data);
      setMetadata(data);
    }else{
      setMetadata(defaultSeo)
    }
  }, [currentPath])

  return (
    <Wrapper>
      <Helmet title={metadata.Title}>
        <meta name="google-site-verification" content="TwPZPfFdTvgtB94eKQtEAbWWU7_S59xKF8WU8WtwkMQ" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/*GENERAL*/}
        <title>{metadata.Title}</title>
        <html lang="en" />
        <meta name="description" content={metadata.Description} />
        {/*TWITTER*/}
        <meta name="twitter:description" content={metadata.Description} />
        <meta name="twitter:title" content={metadata.Title} />
        <meta name="twitter:image" content={metadata.Image} />
        <meta name="twitter:card" content="summary_large_image" />
        {/*OPEN GRAPH*/}
        <meta name="og:image" content={metadata.Image} />
        <meta name="og:description" content={metadata.Description} />
        <meta name="og:title" content={metadata.Title} />
        <meta name="og:site_name" content={metadata.SiteName} />
      </Helmet>
      <Menu
        items={prepareMenuItems(defaultHeaderPropsNew.items, currentPath)}
        animate={false}
        id={"site-menu"}
        hideSublinksForMobile={true}
      />
      {props.children}
      <FooterNew {...defaultFooterProps} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 100vw;
  overflow-x: hidden;

  * {
    font-family: "HK Grotesk", sans-serif;
  }

  margin-bottom: 20px;
  @media ${device.desktop} {
    margin-bottom: 0;
  }
`;
