import React__default from 'react';
import styled from 'styled-components';

const ReferenceRepositoriesIcon = (props) => (React__default.createElement(Icon, Object.assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 100 100" }, props),
    React__default.createElement("g", { id: "Layer_2" },
        React__default.createElement("path", { className: "cls-1", d: "M65,28h15s3.98-4,3.98-4v-7c0-1.66-1.34-3-3-3h-5.98v-4c0-2.21-1.79-4-4-4h-1v5l-5,5v12Z" }),
        React__default.createElement("rect", { className: "cls-1", x: "59", y: "16", width: "4", height: "12" })),
    React__default.createElement("g", { id: "Layer_1" },
        React__default.createElement("polygon", { className: "cls-1", points: "45 79 45 89 50.23 84.78 55 89 55 79 45 79" }),
        React__default.createElement("polygon", { className: "cls-2", points: "73 83 73 85 56 85 56 91.22 50.2 86.09 44 91.09 44 79 46 79 46 86.91 50.26 83.47 54 86.78 54 79 56 79 56 83 73 83" }),
        React__default.createElement("g", null,
            React__default.createElement("rect", { className: "cls-2", x: "35", y: "35", width: "12", height: "2" }),
            React__default.createElement("rect", { className: "cls-2", x: "35", y: "45", width: "17", height: "2" }),
            React__default.createElement("rect", { className: "cls-2", x: "35", y: "55", width: "12", height: "2" })),
        React__default.createElement("path", { className: "cls-2", d: "M32,75h42V19H26v50h2V21h44v52H32c-3.31,0-6,2.69-6,6s2.69,6,6,6h7v-2h-7c-2.21,0-4-1.79-4-4s1.79-4,4-4Z" }))));
const Icon = styled.svg `
  .cls-1{fill:#bae1d3;}.cls-2{fill:#323433;}
`;
ReferenceRepositoriesIcon.defaultProps = {
    "data-testid": "icon-reference-repositories",
};

export { ReferenceRepositoriesIcon };
