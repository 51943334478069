import React__default from 'react';

const DesignersShapesIcon = (props) => (React__default.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "100", height: "100", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "Shapes icon"),
    React__default.createElement("g", { fill: "none", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 0h100v100H0z" }),
        React__default.createElement("path", { fill: "#BAE1D3", d: "M57 54c-6.075 0-11 5.149-11 11.5S50.925 77 57 77" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M48 65.5a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5 9.5 9.5 0 0 0-9.5-9.5 9.5 9.5 0 0 0-9.5 9.5zm2 0a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0z" }),
        React__default.createElement("path", { fill: "#BAE1D3", d: "M25 41.96L33.04 50 49 34.04 40.96 26z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M27.586 42L41 55.414 54.414 42 41 28.586 27.586 42zM41 31.414L51.586 42 41 52.586 30.414 42 41 31.414z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M19 90h58V18H19v72zm2-2V20h54v68H21z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M85.959 85.074c.006-.075.013-.21.021-.399.12-2.965-.182-6.53-1.265-9.975-2.574-8.181-8.65-12.355-18.929-10.104l.428 1.953c9.197-2.013 14.312 1.5 16.593 8.751 1.002 3.188 1.286 6.54 1.175 9.294-.007.166-.014.279-.017.332l1.994.148zM48.682 67.064a112.624 112.624 0 0 1-2.452.77c-1.925.58-4.007 1.16-6.169 1.702-2.849.714-5.618 1.305-8.223 1.731-6.86 1.123-11.949.994-14.342-.629-2.662-1.804-1.952-5.897 3.44-13.035l-1.595-1.206c-5.992 7.931-6.91 13.225-2.967 15.897 2.968 2.012 8.431 2.15 15.788.947 2.664-.436 5.486-1.038 8.385-1.765a138.307 138.307 0 0 0 8.099-2.297c.324-.103.551-.178.672-.218l-.636-1.897zM35.865 19.454c.12-.236.286-.625.45-1.129.371-1.134.54-2.298.387-3.423-.797-5.817-8.714-6.762-26.025-.848l.646 1.892c9.055-3.093 15.353-4.236 19.218-3.774 2.772.33 3.959 1.39 4.18 3 .106.782-.022 1.661-.306 2.533-.13.396-.257.692-.333.841l1.783.908z" }))));
DesignersShapesIcon.defaultProps = {
    "data-testid": "icon-benefits-small",
};

export { DesignersShapesIcon };
