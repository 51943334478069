import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';

// TODO add story?
const TwoColumnContentNodeWrapper = styled.div `
  text-align: center;
  margin-bottom: 45px;

  @media ${device.desktopWithPaddingOnly} {
    padding-left: ${spacing.mobile.padding.default}px;
  }

  @media ${device.tablet} {
    margin-bottom: 0px;
    text-align: left;
  }

  @media ${device.tabletVertical} {
    align-items: center;
    text-align: left;

    > *,
    > * > * {
      text-align: left;
    }
  }

  @media ${device.mobile} {
    p {
      max-width: none;
    }
  }
`;

export { TwoColumnContentNodeWrapper };
