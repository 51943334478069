import React__default from 'react';
import 'styled-components';
import { XolvioLogo } from '../Icons/XolvioLogo.js';

const defaultHeaderPropsNew = {
    animate: false,
    menuOpen: false,
    simpleMenu: false,
    logo: (React__default.createElement(XolvioLogo, { onClick: () => (window.location.href = "/"), style: { cursor: "pointer" } })),
    items: [
        {
            name: "Services",
            url: "/services/",
            sublinks: [
                {
                    name: "Apollo GraphQL Consulting",
                    url: "/services/apollo-graphql-professional-services/",
                },
                {
                    name: "Experience Graph Managed Services",
                    url: "/services/experience-graph-managed-services/",
                },
                {
                    name: "GraphQL Security Review & Remediation",
                    url: "/services/graphql-security-review-remediation/",
                },
                {
                    name: "SDLC & Architecture Consulting",
                    url: "/services/sdlc-architecture-consulting/",
                },
                {
                    name: "Rapid Backend Development",
                    url: "/services/rapid-backend-development/",
                },
                {
                    name: "Legacy Systems Modernization",
                    url: "/services/systems-modernization/",
                },
            ],
        },
        {
            name: "Our Work",
            url: "/case-studies/",
            sublinks: [
                {
                    name: "StudyKIK",
                    url: "/case-studies/studykik-case-study/",
                },
                {
                    name: "Audi",
                    url: "/case-studies/audi-case-study/",
                },
                {
                    name: "Volkswagen of America",
                    url: "/case-studies/volkswagen-case-study/",
                },
            ],
        },
        {
            name: "How We Work",
            url: "/how-we-work/",
        },
        {
            name: "Who We Are",
            url: "/who-we-are/",
        },
        {
            name: "Blog",
            url: "/blog/",
            sublinks: [
                {
                    name: "Articles",
                    url: "/blog/articles/",
                },
                {
                    name: "Dev Notes",
                    url: "/blog/dev-notes/",
                },
            ],
        },
    ],
    primaryButtonText: "Request consultation",
    primaryButtonUrl: "/contact-us",
    socialIcons: {
        twitter: "https://twitter.com/xolvio",
        linkedin: "https://www.linkedin.com/company/xolvio/",
    },
};

export { defaultHeaderPropsNew };
