import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { TextBody } from '../typography.js';
import { Confetti3x5 } from '../Icons/Confetti3x5.js';
import { Confetti7Rows } from '../Icons/Confetti7Rows.js';

const Testimonial = ({ elementWithBackgroundImage, client, industry, project, content, heading, actionButtons, link, }) => (React__default.createElement(TestimonialWrapper, { "data-testid": "testimonial" },
    React__default.createElement(Mobile, { "data-testid": "testimonials-header" }, heading || null),
    React__default.createElement(ImageWithConfettiWrapper, null,
        React__default.createElement(ImageWrapper, null,
            React__default.createElement(Confetti3x5, { className: "non-tablet-up", "data-testid": "testimonial-confetti-top", style: { position: "absolute", right: 0 } }),
            React__default.createElement(Confetti7Rows, { className: "tablet-up", "data-testid": "testimonial-confetti-top", style: { position: "absolute", right: -40, top: -20 } }),
            React__default.createElement(BackgroundImageWrapper, { "data-testid": "testimonial-image" }, elementWithBackgroundImage),
            React__default.createElement(Confetti3x5, { className: "non-tablet-up", style: {
                    transform: "scaleX(-1)",
                    position: "relative",
                    top: -40,
                }, "data-testid": "testimonial-confetti-bottom" }),
            React__default.createElement(Confetti7Rows, { className: "tablet-up", style: {
                    transform: "scaleX(-1)",
                    position: "relative",
                    top: -105,
                    left: -40,
                }, "data-testid": "testimonial-confetti-bottom" }))),
    React__default.createElement(ContentWrapper, null,
        React__default.createElement(TabletUp, { "data-testid": "testimonials-header" }, heading || null),
        React__default.createElement(TextBody, { bold: true, fontSize: "14px" },
            "Client: ",
            client),
        React__default.createElement(TextBody, { bold: true, fontSize: "14px" },
            "Industry: ",
            industry),
        React__default.createElement(TextBody, { bold: true, fontSize: "14px" },
            "Project: ",
            project),
        React__default.createElement(Content, null, content),
        link ? link : null,
        actionButtons ? actionButtons : null)));
const Mobile = styled.div `
  display: block;

  @media ${device.tablet} {
    display: none;
  }
`;
const TabletUp = styled.div `
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;
const TestimonialWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;

  .non-tablet-up {
    display: block;
  }
  .tablet-up {
    display: none;
  }

  @media ${device.tablet} {
    .non-tablet-up {
      display: none;
    }
    .tablet-up {
      display: block;
    }
  }

  @media ${device.tablet} {
    align-items: flex-start;
    flex-direction: row;
    > * {
      max-width: 50%;
      height: 100%;
    }
  }
`;
const ImageWithConfettiWrapper = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Content = styled(TextBody) `
  margin-top: 30px !important;
  min-height: 150px;

  @media ${device.desktop} {
    min-height: 130px;
  }
`;
const ContentWrapper = styled.div `
  > * {
    margin: auto;
  }
  @media ${device.tablet} {
    align-items: flex-start;
    margin-left: 100px;
  }
`;
const BackgroundImageWrapper = styled.div `
  > * {
    width: 472px;
    height: 572px;
    max-width: 100%;
    background-size: cover;

    margin: 17px auto 0;

    @media (max-width: 420px) {
      width: 286px;
      height: 345px;
    }
  }
`;
const ImageWrapper = styled.div `
  position: relative;
  display: block;
  width: 100%;

  position: relative;
  max-width: 100%;
  @media ${device.tablet} {
    width: 472px;
    height: 572px;
  }
`;

export { Testimonial };
