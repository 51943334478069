import React__default from 'react';

const FeaturesSmallIcon = (props) => (React__default.createElement("svg", Object.assign({ width: "64", height: "64", viewBox: "0 0 64 64" }, props),
    React__default.createElement("title", null, "Stacked rectangles icon"),
    React__default.createElement("g", { fill: "none", fillRule: "evenodd" },
        React__default.createElement("path", { d: "M0 0h64v64H0z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M32 55L5 40l27-15 27 15-27 15zm22.882-15L32 27.288 9.118 40 32 52.712 54.882 40z" }),
        React__default.createElement("path", { fill: "#BAE1D3", d: "M32 46L5 31l27-15 27 15z" }),
        React__default.createElement("path", { fill: "#3C3F3E", fillRule: "nonzero", d: "M32 38L5 23 32 8l27 15-27 15zm22.882-15L32 10.288 9.118 23 32 35.712 54.882 23z" }))));
FeaturesSmallIcon.defaultProps = {
    "data-testid": "icon-features-small",
};

export { FeaturesSmallIcon };
