import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';

const HeroBackgroundWrapperNew = styled.div `
  > * {
    align-items: center;
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;

    height: ${(props) => props.height};

    @media ${device.mobile} {
      width: calc(100vw - ${spacing.mobile.padding.default}px);
      margin-left: ${spacing.mobile.padding.default}px;
      max-height: 200px;
    }

    @media ${device.tabletVertical} {
      width: calc(100vw - ${spacing.mobile.padding.default}px);
      max-height: 300px;
    }

    @media ${device.tablet} {
      width: ${(props) => props.inline
    ? `calc(100vw - ${spacing.mobile.padding.default}px)`
    : `100vw - ${spacing.mobile.padding.default * 2}px`};
      margin-left: ${(props) => props.inline
    ? `${spacing.mobile.padding.default}px`
    : `${spacing.mobile.padding.default}`};
    }

      @media ${device.desktop} {
        max-height: initial;
         width: calc(
                 ${spacing.desktop.maxContentWidth}px +
                 (100vw - ${spacing.desktop.maxContentWidth}px) / 2 + 190px);
        margin-left: calc((100vw - ${spacing.desktop.maxContentWidth}px) / 2 - 190px)
    }

    @media ${device.wide} {
      max-height: 750px;
      margin-left: ${(props) => props.inline
    ? `calc((100vw - ${spacing.desktop.maxContentWidth}px) / 2)`
    : `calc((100vw - ${spacing.desktop.maxContentWidth}px) / 2 - 190px)`};

      width: calc(
              ${spacing.desktop.maxContentWidth}px +
              (100vw - ${spacing.desktop.maxContentWidth}px) / 2 ${(props) => props.inline ? "" : " + 190px"});
    );
      max-width: 1700px;
    }
  }
`;
HeroBackgroundWrapperNew.defaultProps = {
    height: "100px",
};

export { HeroBackgroundWrapperNew };
