import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device, b as breakpoints } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import '../typography.js';
import { TeamMember, teamMemberHeight } from './TeamMember.js';

const Team = (props) => (React__default.createElement(Wrapper, null,
    React__default.createElement(InnerWrapper, { membersCount: props.members.length }, props.members.map((member, index) => (React__default.createElement(FadeIn, { index: index, key: member.name.replace(" ", "") },
        React__default.createElement(TeamMember, Object.assign({}, member))))))));
const columnMove = 40;
const computeGridHeight = (membersCount, columnsCount) => `${Math.ceil(membersCount / columnsCount) * (teamMemberHeight + 50)}px`;
const Wrapper = styled.div `
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 100%;
  position: relative;

  /* hide scrollbar */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    height: 0;
    width: 0;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
`;
const InnerWrapper = styled.div `
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin-right: ${spacing.mobile.padding.default}px;
  }

  > *:last-of-type {
    margin-right: 0;
  }

  @media ${device.tabletVertical} {
    display: grid;
    align-items: stretch;
    width: 100%;
    margin-bottom: 80px;

    > * {
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  @media ${device.tabletVertical} and (max-width: ${breakpoints.tablet.start -
    10}px) {
    grid-template-columns: repeat(2, 1fr);
    height: ${(props) => computeGridHeight(props.membersCount, 2)};

    > * {
      &:nth-child(2n - 2) {
        transform: translateY(${columnMove}px);
      }
    }
  }

  @media (min-width: ${breakpoints.tablet.start -
    1}px) and (max-width: ${breakpoints.desktop.start - 1}px) {
    grid-template-columns: repeat(3, 1fr);
    height: ${(props) => computeGridHeight(props.membersCount, 3)};

    > * {
      &:nth-child(3n - 4) {
        transform: translateY(${columnMove}px);
      }

      &:nth-child(3n - 3) {
        transform: translateY(${2 * columnMove}px);
      }
    }
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(4, 1fr);
    height: ${(props) => computeGridHeight(props.membersCount, 4)};

    > * {
      &:nth-child(4n - 6) {
        transform: translateY(${columnMove}px);
      }

      &:nth-child(4n - 5) {
        transform: translateY(${2 * columnMove}px);
      }

      &:nth-child(4n) {
        transform: translateY(${3 * columnMove}px);
      }
    }
  }
`;
const FadeIn = styled.div `
  opacity: 0;
  animation: fade 0.5s forwards;
  animation-delay: ${(props) => props.index * 0.13 || 0}s;

  @keyframes fade {
    0% {
      opacity: 0;
      margin-top: -50px;
    }
    100% {
      opacity: 1;
      margin-top: 0;
    }
  }
`;

export { Team };
