import React__default from 'react';
import styled from 'styled-components';
import { s as spacing } from '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import '../../../colors-c52fac99.js';
import { Subheading, SectionHeader, SectionHeaderSmall, TextBody } from '../typography.js';
import { CenteredContentWrapper } from '../layout/CenteredContentWrapper.js';
import '../ButtonPrimary/ButtonPrimary.js';
import { B as ButtonInlineLink } from '../../../InlineButton-78cf1369.js';
import { Confetti9Rows } from '../Icons/Confetti9Rows.js';

const Services = (props) => (React__default.createElement(CenteredContentWrapper, { id: props.id },
    React__default.createElement(Wrapper, { style: props.style },
        React__default.createElement(Background, null),
        React__default.createElement(ConfettiWrapper, null,
            React__default.createElement(Confetti9Rows, null)),
        React__default.createElement(TitleWrapper, null,
            React__default.createElement(HeaderWrapper, null,
                React__default.createElement(Subheading, null, Array.isArray(props.subheading) ? (React__default.createElement(React__default.Fragment, null, props.subheading.map(sh => React__default.createElement(React__default.Fragment, { key: sh },
                    sh,
                    React__default.createElement("br", null))))) : props.subheading),
                React__default.createElement(SectionHeader, { style: { marginTop: 24 } }, props.title))),
        React__default.createElement(SectionsWrapper, null, props.sections.map((section) => (React__default.createElement(ServiceSection, Object.assign({}, section, { key: section.heading }))))))));
const ServiceSection = (props) => (React__default.createElement(ServiceSectionWrapper, null,
    props.icon ? (React__default.createElement(React__default.Fragment, null,
        React__default.createElement(Anchor, { id: props.sectionId }),
        React__default.createElement(IconWrapper, null, props.icon),
        " ")) : null,
    React__default.createElement(ServiceContentWrapper, null,
        React__default.createElement(SectionHeaderSmall, null, props.heading),
        React__default.createElement(TextBody, null, props.description),
        props.url && (React__default.createElement(ButtonInlineLink, { onClick: props.onClick, href: props.onClick ? undefined : props.url, style: { paddingTop: 20 }, as: "a" }, "Find out more")))));
const Wrapper = styled.div `
  position: relative;
  @media ${device.tabletVertical} {
    display: flex;
    padding: 0 0 250px 0;

    * {
      text-align: left;
    }
  }
`;
const Background = styled.div `
  position: absolute;
  height: 100%;
  right: 50%;
  width: 100%;
  background: #f4f7f6;
  z-index: -1;

  @media ${device.tabletVertical} {
    right: calc(100% - ${spacing.desktop.maxContentWidth}px / 2 + 395px);
    width: 100vw;
  }
`;
const ConfettiWrapper = styled.div `
  display: none;

  @media ${device.tabletVertical} {
    position: absolute;
    transform: translate(calc(-100% - 24px), 64px);
    display: block;
  }
`;
const TitleWrapper = styled.div `
  padding: 0;
  display: flex;

  @media ${device.tabletVertical} {
    padding: 64px 0px;
  }
`;
const HeaderWrapper = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 0;

  @media ${device.tabletVertical} {
    padding: 0;
    display: block;
    max-width: 250px;
    margin: -10px 0;
  }
`;
const SectionsWrapper = styled.div `
  flex-grow: 1;

  @media ${device.tabletVertical} {
    margin-left: 50px;
  }
`;
const ServiceSectionWrapper = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tabletVertical} {
    flex-direction: row;

    :not(:last-child) {
      border-bottom: 1px solid #eaebea;
      margin-bottom: 50px;
    }
  }
`;
const IconWrapper = styled.div `
  @media ${device.tabletVertical} {
    position: relative;
    top: -35px;
    width: 100px;
    height: 100%;
    display: block;
  }
`;
const ServiceContentWrapper = styled.div `
  text-align: center;

  @media ${device.tabletVertical} {
    margin-left: 95px;
    margin-bottom: 60px;
    text-align: left;
  }
`;
const Anchor = styled.a `
  position: relative;
  top: -90px;
  @media ${device.tablet} {
    position: absolute;
    top: 0;
  }
`;

export { Services };
