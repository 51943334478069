import styled, { css } from 'styled-components';

const ImageWrapper = styled.div `
  position: relative;
  display: block;
  margin-bottom: 20px;
  width: 100%;
  ${({ wrapperCss }) => wrapperCss &&
    css `
      ${wrapperCss}
    `}

  > img {
    max-width: 100%;
    ${({ imageCss }) => imageCss &&
    css `
        ${imageCss}
      `}
  }
`;

export { ImageWrapper };
