import styled from 'styled-components';
import { A as AcceptanceGreen } from '../../../colors-c52fac99.js';

const ButtonPrimary = styled.button `
  border-radius: 2px;
  border: none;
  font-family: "HK Grotesk";
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
  padding: 11px 21px;
  background-color: ${AcceptanceGreen};
  cursor: pointer;
  outline: none;
`;
const ButtonPrimaryLink = styled(ButtonPrimary) `
  text-decoration: none;
  display: inline-block;
`;

export { ButtonPrimary, ButtonPrimaryLink };
