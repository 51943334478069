import React__default from 'react';
import styled from 'styled-components';
import '../../../spacing-ad86e0c5.js';
import { d as device } from '../../../device-db5dbdea.js';
import { A as AcceptanceGreen } from '../../../colors-c52fac99.js';
import { CenteredContentWrapper } from '../layout/CenteredContentWrapper.js';

const Modal = ({ children, onCloseClick, isOpen }) => isOpen ? (React__default.createElement(Wrapper, { onClick: onCloseClick },
    React__default.createElement(InnerWrapper, { onClick: (e) => e.stopPropagation() },
        children,
        React__default.createElement(Close, { onClick: onCloseClick })))) : null;
const Close = styled.div `
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0 7px;
  cursor: pointer;
  :after {
    font-size: 2em;
    color: ${AcceptanceGreen};
    content: "\\00d7";
  }

  @media ${device.tablet} {
    padding: 10px;
    padding-right: 24px;

    :after {
      font-size: 2em;
    }
  }

  @media ${device.desktop} {
    padding-right: 48px;
  }
`;
const InnerWrapper = styled(CenteredContentWrapper) `
  background-color: white;
  overflow-y: scroll;
  padding: 16px 12px;
  margin: auto !important;

  height: fit-content;
  position: relative;

  /* hide scrollbar */
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
    height: 0;
    width: 0;
  }
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }

  padding-bottom: 50px;
  @media ${device.tablet} {
    padding: 32px 24px;
  }

  @media ${device.desktop} {
    padding: 64px 48px;
    max-width: 700px;
  }
  margin: auto;
  border: 1px solid #d8dcdb;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: inset 0 -1px 0 0 #e9e9e9, 0 22px 37px 0 rgba(20, 78, 56, 0.15);
`;
const Wrapper = styled.div `
  display: flex;
  align-items: center;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.66);
  z-index: 10000;

  > * {
    max-width: calc(100vw - 48px);
  }
`;

export { Modal };
