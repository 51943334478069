import React__default from 'react';

const DigitalStrategyIcon = (props) => (React__default.createElement("svg", Object.assign({ "data-testid": props["data-testid"], xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 100 100" }, props),
    React__default.createElement("title", null, "Technical Strategy & Architecture icon"),
    React__default.createElement("defs", null,
        React__default.createElement("style", null, `
          .cls01-1 { fill: #bae1d3; }
          .cls01-2 { fill: none; stroke: #323433; stroke-width: 2px; }`)),
    React__default.createElement("g", { id: "Layer_1", "data-name": "Layer 1" },
        React__default.createElement("g", null,
            React__default.createElement("path", { className: "cls01-1", d: "M86.6,36.6a13.4,13.4,0,1,1,0,26.8" }),
            React__default.createElement("rect", { className: "cls01-1", y: "37.38", width: "12.64", height: "25.23" }),
            React__default.createElement("rect", { className: "cls01-2", x: "40.68", y: "11.79", width: "18.37", height: "18.37" }),
            React__default.createElement("rect", { className: "cls01-2", x: "3.46", y: "40.82", width: "18.37", height: "18.37" }),
            React__default.createElement("rect", { className: "cls01-2", x: "40.68", y: "40.82", width: "18.37", height: "18.37" }),
            React__default.createElement("rect", { className: "cls01-2", x: "40.68", y: "69.84", width: "18.37", height: "18.37" }),
            React__default.createElement("circle", { className: "cls01-2", cx: "86.6", cy: "50", r: "10.11" }),
            React__default.createElement("path", { className: "cls01-2", d: "M40.68,21c-18.85,0,0,29-18.85,29H40.68" }),
            React__default.createElement("path", { className: "cls01-2", d: "M21.83,50c18.85,0,0,29,18.85,29" }),
            React__default.createElement("line", { className: "cls01-2", x1: "59.05", y1: "50", x2: "77.42", y2: "50" })))));
DigitalStrategyIcon.defaultProps = {
    "data-testid": "digital-strategy-icon",
};

export { DigitalStrategyIcon };
